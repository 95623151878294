/**
 * SOURCES:
 * - highlithging TOC: https://blog.logrocket.com/create-table-contents-highlighting-react/
 *
 * When displaying info, we'll have grayed out (disabled) fields
 * When updating info, we'll use MUI Textfields, Selects, etc
 *
 * Update: June 19 2023,
 * - using REACT CONTEXT TO store reused component + data..
 * - updating form logic MUST live in useForm
 *
 */
import React, { useState, useEffect, createContext, useContext } from 'react'
import {
  Container,
  ListItemText,
  ListItemIcon as MuiListItemIcon,
  ListItem,
  List,
  Grid,
  Stack,
  Box,
  Typography,
  Divider,
  Button as MuiButton,
  TextField as MuiTextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Card as MuiCard,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material'
import MuiPhoneNumber from 'material-ui-phone-number'

import { useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'

// icons
import PhoneIcon from '@mui/icons-material/Phone'
import EmailIcon from '@mui/icons-material/Email'
import WebIcon from '@mui/icons-material/Web'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import LocationOnIcon from '@mui/icons-material/LocationOn'

import { gql, useMutation } from '@apollo/client'
import { extendUser } from '../../store/modules/auth'
import { useSnackbar } from 'notistack'
import { Link, Navigate, useOutletContext } from 'react-router-dom'
import { formatDistanceToNowStrict } from 'date-fns'

import Autocomplete from '@mui/material/Autocomplete'

// constants
import { CLINICAL_SPECIALTIES, LANGUAGES, MODALITIES, POPULATION } from './constants'
import ROLES from 'utils/constants/roles'
import {
  PROVIDER_PROFILE_FIELDS,
  checkProviderProfile,
  isValidBio,
  isValidPhoneNumber,
  isValidURL,
  validateName,
} from './utils/validation'
import CloseSnackbarAction from 'components/CloseSnackbarAction'
import { AddressList } from './AddressList'
import { RemoteRestrictionsList } from './RemoteRestrictionsList'
import { TEXTFIELD_VARIANT } from 'utils/theme/Theme'
import { DATE_FORMATS } from 'utils/constants/formats'
import { format } from 'date-fns'
import Wizard, { DialogTitleWithClose } from './components/Wizard'
import formatAddress from 'utils/formatAddress'
import CardHeader from 'views/purchase/components/PurchaseCardHeader'
import { isEmpty } from 'utils/isEmpty'
import BusinessAddress from './components/BusinessAddress'
import LearnMore from './components/LearnMore'
import { includesSome } from 'utils/includes'
import ClientConnectionsBanner from './components/ClientConnectionsBanner'
import CommunityProfileInfo from './components/CommunityProfileInfo'
import { InputAdornment } from '@material-ui/core'
import UploadAvatarButton from './components/UploadAvatarButton'
import Avatar from 'components/avatar/Avatar'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { ScrollableComponent } from 'components/scrollable-component'
import { canAccessCommunity } from 'views/community/utils/canAccessCommunity'

const UPDATE_USER = gql`
  mutation updateUser($user: UpdateUserInput!) {
    updateUser(user: $user) {
      id
      email
      firstName
      lastName
      createdAt
      isSuspended
      city
      state
      country
      phone
      dob
      professionalCredentials
      zip
      gender
      birthYear
      providerProfileInfo
    }
  }
`

const Button = ({ children, onClick, margin = '1rem', ...props }) => {
  return (
    <MuiButton variant="text" {...props} sx={{ margin }} onClick={onClick}>
      {children}
    </MuiButton>
  )
}

const ListItemIcon = ({ children }) => {
  return <MuiListItemIcon sx={{ minWidth: 30, color: '#008080' }}>{children}</MuiListItemIcon>
}

const Card = ({ children, className = '' }) => {
  return (
    <MuiCard variant="outlined" className={className}>
      {children}
    </MuiCard>
  )
}

const TextField = ({ children, ...props }) => {
  return (
    <MuiTextField fullWidth {...props}>
      {children}
    </MuiTextField>
  )
}

/**
 * This wrapper is for wrapping professional credentails, emails, and business address
 */
export const VariantWrapper = ({ lastItem, children, ...props }) => {
  if (TEXTFIELD_VARIANT !== 'outlined') {
    return (
      <>
        {children}
        {!lastItem && <Divider sx={{ marginTop: 1 }} />}
      </>
    )
  }

  return (
    <Box sx={{ borderRadius: '4px', border: '1px solid rgba(0, 0, 0, 0.23)', padding: '0.75rem' }}>
      {children}
    </Box>
  )
}

export const ProviderProfileContext = createContext(null)

export const UpdateProfileCard = ({
  id,
  title,
  handleSave,
  handleCancel,
  fields,
  disabled,
  hideButtons,
  children,
  lastUpdatedAt,
}) => {
  const { autoFocusLabelName } = useOutletContext()

  return (
    <MuiCard className="h-full" variant="outlined">
      <Box m={2} p={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={4}>
          <Typography id={4} variant="h4">
            {title}
          </Typography>
          {lastUpdatedAt && (
            <Typography variant="caption" sx={{ color: 'gray !important' }}>
              Last Updated: {format(new Date(lastUpdatedAt), DATE_FORMATS.dateAndTime)}
            </Typography>
          )}
        </Stack>
        <Stack direction="column" spacing={2}>
          {fields.map(({ Component, hide, label, ...props }) => {
            if (hide) {
              return null
            }
            return (
              <ScrollableComponent autoFocus={autoFocusLabelName === label}>
                <Component
                  key={props.id}
                  label={label}
                  {...props}
                  autoFocus={autoFocusLabelName === label}
                />
              </ScrollableComponent>
            )
          })}
          {children}
          {!hideButtons && (
            <Stack direction="row" spacing={2} m={3} pt={1.5}>
              <span>
                <Button margin={'0 0 0 -1rem'} onClick={handleSave} disabled={disabled}>
                  Save
                </Button>
              </span>
              <span>
                <Button margin={0} onClick={handleCancel}>
                  Cancel
                </Button>
              </span>
            </Stack>
          )}
        </Stack>
      </Box>
    </MuiCard>
  )
}

export default function MyProfile() {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const [updateUser] = useMutation(UPDATE_USER)

  // states
  const [editStates, setEditStates] = useState({})
  const [wizardState, setWizardState] = useState()

  // create form for updating user
  const authUser = useSelector((state) => get(state, 'auth.user', {}))
  const {
    id,
    email,
    firstName,
    lastName,
    phone,
    gender,
    professionalCredentials,
    productPreferences,
    providerProfileInfo,
    roles: currentUserRoles,
    avatarThumb,
  } = authUser

  const handleEdit = (label) => () => {
    setEditStates({ ...editStates, [label]: true })
  }
  const handleCancel = (label) => () => {
    setEditStates({ ...editStates, [label]: false })
    resetForm()
  }
  const { loading, setLoading } = useOutletContext()

  const [error, setError] = useState(null)

  const [openConfirm, setOpenConfirm] = useState(false)
  const handleSaveProviderProfile = async () => {
    const isValidOpenToClients = checkProviderProfile(form)
    if (!isValidOpenToClients && form.openToNewClients) {
      setOpenConfirm(true)
    } else {
      handleSave()
    }
  }

  /**
   *  updatedForm may have values for provider profile info, which we will have to nest under an object :(
   */
  const handleSave = async (updatedForm = {}, hideUI = false, removeBusinessAddress = false) => {
    !hideUI && (await setLoading(true))

    // if we have current address selected in the wizard..
    const addresses = (updatedForm.addresses || form.addresses || []).map((location) => {
      if (location?.locationName === 'Business Address') {
        return {
          ...location,
          isInPersonLocation: hasCurrentAddressSelected && !removeBusinessAddress ? 'Yes' : 'No',
        }
      } else {
        return { ...location }
      }
    })

    // we need to nest due to provider profile info being multi level
    // populate form with new info first, then default to the old info..
    const newForm = {
      id: updatedForm.id || form.id,
      firstName: updatedForm?.firstName?.trim() || form.firstName,
      lastName: updatedForm?.lastName?.trim() || form.lastName,
      gender: updatedForm.gender || form.gender,
      professionalCredentials: updatedForm.professionalCredentials || form.professionalCredentials,
      productPreferences: updatedForm.productPreferences || form.productPreferences,
      phone: updatedForm.phone || form.phone,
      providerProfileInfo: {
        openToNewClients: updatedForm.openToNewClients || form.openToNewClients,
        addresses,
        modalities: updatedForm.modalities || form.modalities,
        clinicalSpecialties: updatedForm.clinicalSpecialties || form.clinicalSpecialties,
        deliveryModels: updatedForm.deliveryModels || form.deliveryModels,
        languagesSpoken: updatedForm.languagesSpoken || form.languagesSpoken,
        population: updatedForm.population || form.population,
        acceptInsurance: updatedForm.acceptInsurance || form.acceptInsurance,
        gettingStartedClickedAt:
          updatedForm.gettingStartedClickedAt || form.gettingStartedClickedAt,
        isCommunityTosAccepted: updatedForm.isCommunityTosAccepted || form.isCommunityTosAccepted,
        website: updatedForm.website || form.website,
        bio: updatedForm.bio || form.bio,
        showModalities: updatedForm.showModalities || form.showModalities,
        showClinicalSpecialties:
          updatedForm.showClinicalSpecialties || form.showClinicalSpecialties,
        showPopulation: updatedForm.showPopulation || form.showPopulation,
        showLanguagesSpoken: updatedForm.showLanguagesSpoken || form.showLanguagesSpoken,
        showState: updatedForm.showState || form.showState,
        showWebsite: updatedForm.showWebsite || form.showWebsite,
        showBio: updatedForm.showBio || form.showBio,

        // there is logic for fields below
        professionalInfoUpdatedAt:
          updatedForm.professionalInfoUpdatedAt || form.professionalInfoUpdatedAt,
        addressesUpdatedAt: updatedForm.addressesUpdatedAt || form.addressesUpdatedAt,
        addressesSelectedNoAt: updatedForm.addressesSelectedNoAt || form.addressesSelectedNoAt,
        remoteRestrictions: updatedForm.remoteRestrictions || form.remoteRestrictions,
        remoteRestrictionsUpdatedAt:
          updatedForm.remoteRestrictionsUpdatedAt || form.remoteRestrictionsUpdatedAt,
        remoteRestrictionsSelectedNoAt:
          updatedForm.remoteRestrictionsSelectedNoAt || form.remoteRestrictionsSelectedNoAt,
      },
    }

    // generate dynamic values (time)
    const currentTime = new Date()

    // clears remote restrictions if no was ever selected (ie not in delivery models)
    const remoteRestrictions = newForm.providerProfileInfo.deliveryModels.includes('Remote')
      ? newForm.providerProfileInfo.remoteRestrictions
      : []

    // 1. use values from params
    // 2. create new value if we see an update
    // 3. use old value
    const addressesUpdatedAt =
      updatedForm.addressesUpdatedAt ||
      (updatedForm.addresses ? currentTime.toISOString() : undefined) ||
      form.addressesUpdatedAt

    // 1. use values from params
    // 2. create new value if we see an update
    // 3. use old value
    const remoteRestrictionsUpdatedAt =
      updatedForm.remoteRestrictionsUpdatedAt ||
      (updatedForm.remoteRestrictions ? currentTime.toISOString() : undefined) ||
      form.remoteRestrictionsUpdatedAt

    const hasInPersonDeliveryAddresses = newForm.providerProfileInfo.addresses.find(
      ({ isInPersonLocation }) => isInPersonLocation === true
    )
    const addressesSelectedNoAt =
      // try getting value from params
      updatedForm.addressesSelectedNoAt ||
      // if we're at wizard and selected no
      (updatedForm.deliveryModels && !updatedForm.deliveryModels.includes('In Person')
        ? currentTime.toISOString()
        : undefined) ||
      // if we previously had addresses, and now we don't..
      (!hasInPersonDeliveryAddresses && form.addressesUpdatedAt)
        ? currentTime.toISOString()
        : undefined || form.addressesSelectedNoAt

    const remoteRestrictionsSelectedNoAt =
      updatedForm.remoteRestrictionsSelectedNoAt ||
      // if we'r at wizard and selected no to remote
      (updatedForm.deliveryModels &&
        !updatedForm.deliveryModels.includes('Remote') &&
        currentTime.toISOString()) ||
      // if we have previously updated remote restrictions and now its an empty array
      (newForm.providerProfileInfo.remoteRestrictionsUpdatedAt &&
        !newForm.providerProfileInfo.remoteRestrictions.length)
        ? currentTime.toISOString()
        : undefined || form.remoteRestrictionsSelectedNoAt

    // use current time if we have updated provider profile fields
    const professionalInfoUpdatedAt =
      PROVIDER_PROFILE_FIELDS.reduce((a, c) => {
        return !!updatedForm[a.key] || c
      }, false) && currentTime.toISOString()
    /**
     * This is only checked if:
     * 1. we have valid provider profile fields
     * 2. we have at least one delivery model
     * 3. we have previously checked it
     * 4. we have valid addresses and remote delivery
     */
    // uncheck openToNewClients if any mandatory values fails test
    const isValidOpenToClients = checkProviderProfile({
      modalities: newForm.providerProfileInfo.modalities,
      clinicalSpecialties: newForm.providerProfileInfo.clinicalSpecialties,
      population: newForm.providerProfileInfo.population,
      languagesSpoken: newForm.providerProfileInfo.languagesSpoken,
      acceptInsurance: newForm.providerProfileInfo.acceptInsurance,
      gender: newForm.gender,
    })
    const openToNewClients = !!(
      // 0 if we select no via UI, this becomes no
      (
        updatedForm.openToNewClients !== false &&
        // 1
        isValidOpenToClients &&
        // 2
        newForm.providerProfileInfo.deliveryModels.length &&
        // 3
        newForm.providerProfileInfo.openToNewClients &&
        // 4
        ((newForm.providerProfileInfo.remoteRestrictions.length &&
          newForm.providerProfileInfo.deliveryModels.includes('Remote')) ||
          (newForm.providerProfileInfo.addresses.find(
            ({ isInPersonLocation }) => isInPersonLocation === 'Yes'
          ) &&
            newForm.providerProfileInfo.deliveryModels.includes('In Person')))
      )
    )

    if (validateName('firstName', newForm.firstName)) {
      setError('Invalid firstName')
      await setLoading(false)
      return
    } else if (validateName('lastName', newForm.lastName)) {
      setError('Invalid last name')
      await setLoading(false)
      return
    } else if (newForm.phone && !isValidPhoneNumber(newForm.phone)) {
      setError('Invalid phone number')
      await setLoading(false)
      return
    }

    try {
      const res = await updateUser({
        variables: {
          user: {
            ...newForm,
            providerProfileInfo: {
              ...newForm.providerProfileInfo,
              openToNewClients,
              professionalInfoUpdatedAt,
              remoteRestrictions,
              addressesUpdatedAt,
              remoteRestrictionsUpdatedAt,
              addressesSelectedNoAt,
              remoteRestrictionsSelectedNoAt,
            },
          },
        },
      })
      await dispatch(extendUser(res.data.updateUser))
      await setForm({
        ...newForm,
        ...newForm.providerProfileInfo,
        openToNewClients,
        professionalInfoUpdatedAt,
        remoteRestrictions,
        addressesUpdatedAt,
        remoteRestrictionsUpdatedAt,
        addressesSelectedNoAt,
        remoteRestrictionsSelectedNoAt,
      })

      // don't load snackbar if wizard is open..
      if (!openWizard && !hideUI) {
        enqueueSnackbar('Update successful', { variant: 'success', action: CloseSnackbarAction })
      }
    } catch (err) {
      !hideUI &&
        enqueueSnackbar(`Failed to save. ${err.message}`, {
          variant: 'error',
          action: CloseSnackbarAction,
        })
      resetForm()
    } finally {
      setEditStates({})
      await setLoading(false)
    }
  }

  const updateBasicInfo = editStates.basicInfo
  const updateProfessionalInfo = editStates.professionalInfo
  const sspCertificationCompletedDate = productPreferences?.sspCertification?.completedAt
  const focusCertificationCompletedDate = productPreferences?.focusCertification?.completedAt

  const sspInTrainingOnly = get(productPreferences, 'sspCertification.trainingOnly', false)
  const focusInTrainingOnly = get(productPreferences, 'focusCertification.trainingOnly', false)

  // get years of certification for header
  // code is similar to demographic Info
  const getYearsOfCertification = () => {
    const focusCertificationDistanceToNowStrict =
      focusCertificationCompletedDate &&
      formatDistanceToNowStrict(new Date(focusCertificationCompletedDate))
    const sspCertificationDistanceToNowStrict =
      sspCertificationCompletedDate &&
      formatDistanceToNowStrict(new Date(sspCertificationCompletedDate))
    return {
      focusCertificationDistanceToNowStrict,
      sspCertificationDistanceToNowStrict,
    }
  }
  const {
    focusCertificationDistanceToNowStrict,
    sspCertificationDistanceToNowStrict,
  } = getYearsOfCertification(productPreferences)

  const {
    form,
    setForm,
    errors,
    handleTextField,
    handleLists,
    handleCheckBox,
    resetForm,
    onLoadValues,
    upsertAddress,
    handleFirstClick,
  } = useMyForm({
    id,
    email,
    firstName,
    lastName,
    phone,
    gender,
    professionalCredentials,
    productPreferences,
    ...providerProfileInfo,
  })

  const PROFESSIONAL_INFO = [
    {
      label: 'Modalities Used',
      type: 'list',
      value: form.modalities,
      isActive: true,
    },
    {
      label: 'Clinical Specialties',
      type: 'list',
      value: form.clinicalSpecialties,
      isActive: true,
    },

    {
      label: 'Population',
      type: 'list',
      value: form.population,
      isActive: true,
    },
    { label: 'Accept Insurances', value: form.acceptInsurance, isActive: true },
    {
      label: 'Languages Spoken',
      type: 'list',
      value: form.languagesSpoken,
      isActive: true,
    },
    {
      label: 'Gender',
      value: form.gender?.includes('Woman')
        ? 'Woman'
        : form.gender?.includes('Man')
        ? 'Man'
        : form.gender,
      isActive: true,
    },
  ].filter(({ isActive }) => isActive)

  const [showChangeBusinessAddress, setShowChangeBusinessAddress] = useState(false)
  const isBusinessAddress = wizardState?.includes('BUSINESS_ADDRESS')
  const isEditBasicInfo = wizardState?.includes('BASIC_INFO/EDIT')

  const isInvalidPhoneNumber = form.phone?.length > 1 && !isValidPhoneNumber(form.phone)
  const phoneNumberErrorText = isInvalidPhoneNumber && 'Please enter a valid phone number'

  const isInvalidWebsite = !isValidURL(form.website)
  const websiteErrorText = isInvalidWebsite && 'Please enter a valid website link'

  const isInvalidBio = !isValidBio(form.bio)
  const bioErrorText = isInvalidBio && 'Bio must be no longer than 300 characters'

  const BASIC_INFO = [
    {
      Component: TextField,
      label: 'First Name',
      id: 'firstName',
      value: form.firstName,
      onChange: handleTextField('firstName'),
      error: !!validateName('firstName', form.firstName),
      helperText: validateName('firstName', form.firstName),
    },
    {
      Component: TextField,
      label: 'Last Name',
      id: 'lastName',
      value: form.lastName,
      onChange: handleTextField('lastName'),
      error: !!validateName('lastName', form.lastName),
      helperText: validateName('lastName', form.lastName),
    },
    {
      id: 'professionalCredentials',
      Component: () => (
        <VariantWrapper>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <Typography sx={{ color: 'rgba(0, 0, 0, 0.42)' }} variant="body2">
                Professional Credentials
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography variant="body2" sx={{ color: 'gray !important', fontWeight: '500' }}>
                {form.professionalCredentials}
              </Typography>
              <Link to="/my/account/change-professional-credentials">
                <Typography variant="caption">Change professional credentials</Typography>
              </Link>
            </Grid>
          </Grid>
        </VariantWrapper>
      ),
    },
    {
      id: 'email',
      Component: () => (
        <VariantWrapper>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <Typography sx={{ color: 'rgba(0, 0, 0, 0.42)' }} variant="body2">
                Email
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              {currentUserRoles.some((role) =>
                [...ROLES.PROVIDER_ROLES, ROLES.BILLING].includes(role)
              ) ? (
                <>
                  <Typography variant="body2" sx={{ color: 'gray !important', fontWeight: '500' }}>
                    {form.email}
                  </Typography>
                  <Link to="/my/account/change-email">
                    <Typography variant="caption">Change email</Typography>
                  </Link>
                </>
              ) : (
                <Typography variant="body2" sx={{ color: 'gray !important' }}>
                  {form.email}
                </Typography>
              )}
            </Grid>
          </Grid>
        </VariantWrapper>
      ),
    },
    {
      Component: MuiPhoneNumber,
      variant: TEXTFIELD_VARIANT,
      label: 'Phone',
      id: 'phone',
      value: form.phone,
      onChange: handleTextField('phone'),
      name: 'phonemask',
      disableAreaCodes: true,
      preferredCountries: ['ca', 'us'],
      defaultCountry: 'us',
      sx: { width: '100%' },
      error: isInvalidPhoneNumber,
      helperText: phoneNumberErrorText,
    },
    {
      Component: TextField,
      label: 'Website',
      id: 'website',
      value: form.website,
      onChange: handleTextField('website'),
      error: isInvalidWebsite,
      helperText: websiteErrorText,
      InputProps: {
        startAdornment: <InputAdornment position="start">https://</InputAdornment>,
      },
    },
    {
      Component: TextField,
      label: 'Bio',
      id: 'bio',
      multiline: true,
      value: form.bio,
      onChange: handleTextField('bio'),
      error: isInvalidBio,
      helperText: bioErrorText,
    },
    {
      Component: BusinessAddress,
      id: 'businessAddress',
    },
  ]

  // set the title on load
  const { setTitle, setAutoFocusLabelName } = useOutletContext()
  useEffect(() => {
    setTitle('My Profile')
    // eslint-disable-next-line
  }, [])

  // open dialog
  const [openDialog, setOpenDialog] = useState(false)
  const [openWizard, setOpenWizard] = useState(false)

  const handleCloseDialog = () => {}
  const onDismiss = () => {
    setOpenDialog(false)
  }

  // this is addressForm
  const onError = (_message) => {
    const message = _message ?? 'Address must be selected for in-person delivery'
    enqueueSnackbar(message, {
      variant: 'error',
      action: CloseSnackbarAction,
    })
  }

  // this is for wizard
  const [activeStep, setActiveStep] = useState(0)
  const [wizardErrors, setWizardErrors] = useState({})
  const [showErrors, setShowErrors] = useState(false)

  // check if business address is selected
  // const isInPersonLocation = get(form, 'addresses[0].isInPersonLocation', 'No')
  const isInPersonLocation = form?.addresses?.find(
    ({ locationName }) => locationName === 'Business Address'
  )?.isInPersonLocation

  const [hasCurrentAddressSelected, setHasCurrentAddressSelected] = useState(
    isInPersonLocation === 'Yes'
  )

  // additional logic for unpaid subscription
  const { hasSspProducts, hasFocusProducts } = useSelector((state) => state.ff)
  const [openUnavailableModal, setOpenUnavailableModal] = useState(false)
  const organizationSettings = useSelector((state) =>
    get(state, 'auth.user.organization.settings', {})
  )
  const organizationProductsStatus = useSelector((state) => get(state, 'organization.products', {}))

  useEffect(() => {
    if (!hasSspProducts && form.openToNewClients) {
      handleSave({ openToNewClients: false }, true)
    }
  }, [])

  const handleClose = () => {
    setOpenWizard(false)
    setWizardState()
  }

  const [showRestrictions, setShowRestriction] = useState(
    form?.remoteRestrictions?.length ? 'Yes' : 'No'
  )

  const handleAddPhoneNumber = () => {
    setAutoFocusLabelName('Phone')
    handleEdit('basicInfo')()
  }

  // find business address
  const addresses = get(form, 'addresses', [{}])
  const businessAddress =
    addresses.find((address) => address?.locationName === 'Business Address') || {}
  const formattedBusinessAddress = !isEmpty(businessAddress) ? formatAddress(businessAddress) : ''

  const handleAddBusinessAddress = () => {
    handleEdit('basicInfo')()
    setAutoFocusLabelName('Address')
    const _businessAddressIndex = addresses.findIndex(
      (address) => address?.locationName === 'Business Address'
    )
    const businessAddressIndex =
      _businessAddressIndex !== -1 ? _businessAddressIndex : addresses.length
    setWizardState(`BASIC_INFO/EDIT_${businessAddressIndex}`)
  }

  // if you are a billing only user, you should not be here, and your default tab is organization
  const isProvider = includesSome(currentUserRoles, [ROLES.PROVIDER, ROLES.PROVIDER_ALL_CLIENTS])
  const isBilling = includesSome(currentUserRoles, [ROLES.BILLING])

  if (isBilling && !isProvider) {
    return <Navigate to="/my/organization" />
  }

  const isSspTrainingOnly = get(productPreferences, 'sspCertification.trainingOnly', false)
  const isFocusTrainingOnly = get(productPreferences, 'focusCertification.trainingOnly', false)

  const isCommunityEnabled = canAccessCommunity({
    isProvider,
    hasSspProducts,
    hasFocusProducts,
    organizationSettings,
    organizationProductsStatus,
    providerProfileInfo,
    productPreferences,
  })

  return (
    <ProviderProfileContext.Provider
      value={{
        form,
        providerProfileInfo,
        setForm,
        onLoadValues,
        upsertAddress,
        handleLists,
        wizardState,
        setWizardState,
        handleTextField,
        handleSave,
        activeStep,
        setActiveStep,
        wizardErrors,
        setWizardErrors,
        showErrors,
        setShowErrors,
        hasCurrentAddressSelected,
        setHasCurrentAddressSelected,
        setOpenWizard,
        loading,
        setLoading,
        handleCheckBox,
        showChangeBusinessAddress,
        setShowChangeBusinessAddress,
        showRestrictions,
        setShowRestriction,
      }}
    >
      <Container maxWidth="lg" sx={{ margin: '1rem 0 1rem 0' }}>
        <Grid container direction={{ lg: 'row', md: 'column' }} spacing={2}>
          {((productPreferences?.sspCertification && !isSspTrainingOnly) ||
            (productPreferences?.focusCertification && !isFocusTrainingOnly)) && (
            <ClientConnectionsBanner
              providerProfileInfo={providerProfileInfo}
              productPreferences={productPreferences}
              setOpenUnavailableModal={setOpenUnavailableModal}
              handleFirstClick={handleFirstClick}
              setOpenDialog={setOpenDialog}
            />
          )}

          {!updateBasicInfo && (
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Card className="h-full">
                <CardHeader
                  avatar={
                    <Stack direction="column" alignItems="center">
                      <Avatar
                        aria-label="provider-avatar"
                        style={{ width: 60, height: 60 }}
                        firstName={firstName}
                        lastName={lastName}
                        src={avatarThumb}
                      />
                      <UploadAvatarButton setLoading={setLoading} />
                    </Stack>
                  }
                  title={`${firstName} ${lastName}`}
                  subheader={professionalCredentials}
                  titleTypographyProps={{
                    variant: 'h4',
                    fontWeight: 500,
                  }}
                  subheaderTypographyProps={{
                    fontSize: '1.1rem',
                  }}
                  sx={{ p: 2, m: 0 }}
                />

                <Stack
                  sx={{
                    flexDirection: { xs: 'column', sm: 'column', md: 'column', lg: 'row' },
                  }}
                  mx={1}
                >
                  <List sx={{ padding: 0 }}>
                    <Stack direction="row" spacing={1}>
                      <Stack sx={{ width: '300px' }}>
                        <ListItem dense>
                          <ListItemIcon>
                            <PhoneIcon />
                          </ListItemIcon>
                          {phone && <ListItemText primary={phone || ''} />}
                          {!phone && (
                            <ListItemText
                              onClick={handleAddPhoneNumber}
                              primary="Add Phone Number"
                              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                            />
                          )}
                        </ListItem>
                        {email && (
                          <ListItem dense>
                            <ListItemIcon>
                              <EmailIcon />
                            </ListItemIcon>
                            <ListItemText primary={email} />
                          </ListItem>
                        )}
                        {/* current_address_goes_here */}
                        <>
                          {formattedBusinessAddress && (
                            <ListItem dense>
                              <ListItemIcon>
                                <LocationOnIcon />
                              </ListItemIcon>
                              <ListItemText primary={formattedBusinessAddress} />
                            </ListItem>
                          )}
                          {!formattedBusinessAddress && (
                            <ListItem dense>
                              <ListItemIcon>
                                <LocationOnIcon />
                              </ListItemIcon>
                              <ListItemText
                                onClick={handleAddBusinessAddress}
                                primary="Add business address"
                                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                              />
                            </ListItem>
                          )}
                        </>

                        <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
                          {sspCertificationDistanceToNowStrict && (
                            <ListItem dense>
                              <ListItemIcon>
                                <CheckCircleIcon />
                              </ListItemIcon>
                              <ListItemText primary="SSP Certified" />
                            </ListItem>
                          )}
                          {focusCertificationDistanceToNowStrict && (
                            <ListItem dense>
                              <ListItemIcon>
                                <CheckCircleIcon />
                              </ListItemIcon>
                              <ListItemText primary="ILS Certified" />
                            </ListItem>
                          )}
                        </Box>
                        {form.website && (
                          <ListItem dense>
                            <ListItemIcon>
                              <WebIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href={`https://${form.website}`}
                                  className="text-link"
                                >
                                  https://{form.website}
                                </a>
                              }
                            />
                          </ListItem>
                        )}
                        {form.bio && (
                          <ListItem dense>
                            <ListItemIcon>
                              <AccountBoxIcon />
                            </ListItemIcon>
                            <ListItemText primary={form.bio} />
                          </ListItem>
                        )}
                      </Stack>
                      <Stack sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                        {sspCertificationDistanceToNowStrict && (
                          <ListItem dense>
                            <ListItemIcon>
                              <CheckCircleIcon />
                            </ListItemIcon>
                            <ListItemText primary="SSP Certified" />
                          </ListItem>
                        )}
                        {focusCertificationDistanceToNowStrict && (
                          <ListItem dense>
                            <ListItemIcon>
                              <CheckCircleIcon />
                            </ListItemIcon>
                            <ListItemText primary="ILS Certified" />
                          </ListItem>
                        )}
                      </Stack>
                    </Stack>
                  </List>
                </Stack>
                <MuiButton sx={{ mx: 2, mb: 3, mt: 2 }} onClick={handleEdit('basicInfo')}>
                  Edit Basic Info
                </MuiButton>
              </Card>
            </Grid>
          )}
          {/* Basic Info */}
          {updateBasicInfo && (
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <UpdateProfileCard
                title="Basic Info"
                id="basic-info"
                handleCancel={handleCancel('basicInfo')}
                handleSave={handleSave}
                fields={BASIC_INFO}
                error={error}
                disabled={
                  validateName('firstName', form.firstName) ||
                  validateName('lastName', form.lastName) ||
                  isInvalidPhoneNumber ||
                  isInvalidWebsite ||
                  isInvalidBio
                }
                hideButtons={isBusinessAddress || isEditBasicInfo}
              />
            </Grid>
          )}

          {isCommunityEnabled && (
            <CommunityProfileInfo
              form={form}
              handleSave={handleSave}
              editStates={editStates}
              setEditStates={setEditStates}
            />
          )}

          <Wizard
            open={openWizard}
            handleClose={handleClose}
            form={form}
            errors={errors}
            handleLists={handleLists}
            handleTextField={handleTextField}
            onError={onError}
            resetForm={resetForm}
            providerProfileInfo={providerProfileInfo}
          />

          <Grid item xs={12}>
            <Card>
              <Stack direction="row" justifyContent="space-between" alignItems="center" m={2} p={2}>
                <Typography id="professional-info" variant="h4">
                  Professional Info
                </Typography>
                {providerProfileInfo.professionalInfoUpdatedAt && (
                  <Typography
                    id="professional-info-last-updated-at"
                    variant="caption"
                    sx={{ color: 'gray !important' }}
                  >
                    Last Updated:{' '}
                    {format(
                      new Date(providerProfileInfo.professionalInfoUpdatedAt),
                      DATE_FORMATS.dateAndTime
                    )}
                  </Typography>
                )}
              </Stack>

              {!updateProfessionalInfo && (
                <Stack direction="column" divider={<Divider />} spacing={2} mx={2} px={2}>
                  {PROFESSIONAL_INFO.map(({ label, value, type }) => (
                    <Grid container key={label}>
                      <Grid item xs={12} sm={4} height="48px" alignContent="center">
                        <Typography variant="body2">{label}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={8} alignContent="center">
                        {type !== 'list' && (
                          <Typography variant="body2" mx={2} sx={{ color: 'gray !important' }}>
                            {value}
                          </Typography>
                        )}
                        {type === 'list' &&
                          value?.map((label) => <Chip sx={{ m: 1 }} label={label} key={label} />)}
                      </Grid>
                    </Grid>
                  ))}
                </Stack>
              )}

              {updateProfessionalInfo && (
                <EditProviderProfile
                  form={form}
                  errors={errors}
                  handleLists={handleLists}
                  handleTextField={handleTextField}
                />
              )}

              <Stack direction="row" my={2}>
                {!updateProfessionalInfo && (
                  <Box component="span" sx={{ ml: 0.75 }}>
                    <Button onClick={handleEdit('professionalInfo')}>Edit Professional Info</Button>
                  </Box>
                )}
                {updateProfessionalInfo && (
                  <>
                    <span>
                      <Button onClick={handleSaveProviderProfile}>Save</Button>
                    </span>
                    <span>
                      <Button onClick={handleCancel('professionalInfo')}>Cancel</Button>
                    </span>
                  </>
                )}
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <AddressList
              handleSave={handleSave}
              handleCancel={resetForm}
              handleChange={handleLists('addresses')}
              values={form.addresses}
              inPersonSelected={form?.deliveryModels?.includes('In Person')}
              onError={onError}
              lastUpdatedAt={providerProfileInfo.addressesUpdatedAt}
            />
          </Grid>
          {((productPreferences?.sspCertification && !sspInTrainingOnly) ||
            (productPreferences?.focusCertification && !focusInTrainingOnly)) && (
            <Grid item xs={12}>
              <RemoteRestrictionsList
                lastUpdatedAt={providerProfileInfo.remoteRestrictionsUpdatedAt}
              />
            </Grid>
          )}
        </Grid>
      </Container>
      <ImportantGuidelinesAndExpectations
        open={openDialog}
        handleClose={handleCloseDialog}
        onDismiss={onDismiss}
      />
      <ConfirmSave
        open={openConfirm}
        handleSave={handleSave}
        form={form}
        setOpen={setOpenConfirm}
      />
      <UnavailableModal
        open={openUnavailableModal}
        handleClose={() => setOpenUnavailableModal(false)}
      />
    </ProviderProfileContext.Provider>
  )
}

export const useMyForm = ({ ...data }) => {
  const [form, setForm] = useState({
    remoteRestrictions: [],
    addresses: [],
    modalities: [],
    clinicalSpecialties: [],
    deliveryModels: [],
    population: [],
    languagesSpoken: [],
    website: '',
    bio: '',
    openToNewClients: false,
    showModalities: true,
    showClinicalSpecialties: true,
    showPopulation: true,
    showLanguagesSpoken: true,
    showState: true,
    showWebsite: true,
    showBio: true,
    ...data,
  })
  const [errors, setErrors] = useState({})

  const onLoadValues = { ...data }

  const handleTextField = (label) => (event) => {
    if (label === 'phone') {
      setForm({ ...form, [label]: event === '+' ? '' : event })
    } else {
      setForm({ ...form, [label]: event.target.value })
    }
    setErrors({})
  }

  const handleLists = (type) => (_, newValues) => {
    if (!newValues) {
      return
    }
    setForm({
      ...form,
      [type]: [...newValues],
    })
    setErrors({})
  }

  const handleCheckBox = (label) => (event) => {
    setForm({ ...form, [label]: event.target.checked })
    setErrors({})
  }

  const handleFirstClick = () => {
    setForm({ ...form, gettingStartedClickedAt: new Date().toISOString() })
  }

  const resetForm = () => {
    setForm({
      remoteRestrictions: [],
      addresses: [],
      modalities: [],
      clinicalSpecialties: [],
      deliveryModels: [],
      population: [],
      languagesSpoken: [],
      ...onLoadValues,
    })
    setErrors({})
  }

  const upsertAddress = (id, type, currentArray) => {
    const newArray =
      id === form[type].length
        ? [...form[type], currentArray] // add new address
        : form[type]?.map((val, idx) => (idx === id ? currentArray : val))

    setForm({ ...form, [type]: [...newArray] })
  }

  return {
    form,
    setForm,
    handleTextField,
    handleLists,
    handleCheckBox,
    resetForm,
    errors,
    setErrors,
    onLoadValues,
    upsertAddress,
    handleFirstClick,
  }
}

export const EditProviderProfile = ({
  errors: _errors,
  showSome = {},
  showAll = true,
  isWizard = false,
}) => {
  const { form, handleLists, handleTextField, showErrors } = useContext(ProviderProfileContext)
  const { autoFocusLabelName } = useOutletContext()

  const errors = showErrors ? _errors : {}

  return (
    // if we're from wizard.. reduce margin
    <Stack direction="column" spacing={2} mx={isWizard ? 0 : 2} px={isWizard ? 0 : 2}>
      {(showSome.modalities || showAll) && (
        <ScrollableComponent autoFocus={autoFocusLabelName === 'I use the following modalities'}>
          <Autocomplete
            multiple
            id="tags-standard"
            options={MODALITIES}
            value={form.modalities}
            renderInput={(params) => (
              <TextField
                {...params}
                autoFocus={autoFocusLabelName === 'I use the following modalities'}
                label="I use the following modalities"
                placeholder="Modalities Used"
                error={errors.modalities}
              />
            )}
            onChange={handleLists('modalities')}
          />
        </ScrollableComponent>
      )}

      {(showSome.clinicalSpecialties || showAll) && (
        <ScrollableComponent
          autoFocus={
            autoFocusLabelName === 'I offer support in these clinical specialties in my practice'
          }
        >
          <Autocomplete
            multiple
            id="tags-standard"
            options={CLINICAL_SPECIALTIES}
            value={form.clinicalSpecialties}
            renderInput={(params) => (
              <TextField
                {...params}
                label="I offer support in these clinical specialties in my practice"
                placeholder="Clinical Specialties"
                error={errors.clinicalSpecialties}
                autoFocus={
                  autoFocusLabelName ===
                  'I offer support in these clinical specialties in my practice'
                }
              />
            )}
            onChange={handleLists('clinicalSpecialties')}
          />
        </ScrollableComponent>
      )}

      {(showSome.population || showAll) && (
        <ScrollableComponent autoFocus={autoFocusLabelName === 'I specialize in working with'}>
          <Autocomplete
            multiple
            id="tags-standard"
            options={POPULATION}
            value={form.population}
            renderInput={(params) => (
              <TextField
                {...params}
                label="I specialize in working with"
                placeholder="Population"
                error={errors.population}
                autoFocus={autoFocusLabelName === 'I specialize in working with'}
              />
            )}
            onChange={handleLists('population')}
          />
        </ScrollableComponent>
      )}

      {(showSome.acceptInsurance || showAll) && (
        <ScrollableComponent autoFocus={autoFocusLabelName === 'I accept Insurance'}>
          <Box sx={{ pt: 1, width: '100%' }}>
            <FormControl
              error={errors.acceptInsurance}
              fullWidth
              autoFocus={autoFocusLabelName === 'I accept Insurance'}
            >
              <InputLabel id="input-accept-insurance-label">I accept insurance</InputLabel>
              <Select
                label="I accept Insurance"
                labelId="select-accept-insurance-label"
                id="select-accept-insurance-id"
                value={form.acceptInsurance}
                onChange={handleTextField('acceptInsurance')}
              >
                <MenuItem data-test="confirm-accept-insurance-yes" value={'Yes'}>
                  Yes
                </MenuItem>
                <MenuItem data-test="confirm-accept-insurance-no" value={'No'}>
                  No
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </ScrollableComponent>
      )}

      {(showSome.languagesSpoken || showAll) && (
        <Box sx={{ pt: 1, width: '100%' }}>
          <ScrollableComponent autoFocus={autoFocusLabelName === 'I speak the following languages'}>
            <Autocomplete
              multiple
              fullWidth
              id="tags-standard"
              options={LANGUAGES}
              value={form.languagesSpoken}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="I speak the following languages"
                  placeholder="Languages Spoken"
                  error={errors.languagesSpoken}
                  autoFocus={autoFocusLabelName === 'I speak the following languages'}
                />
              )}
              onChange={handleLists('languagesSpoken')}
            />
          </ScrollableComponent>
        </Box>
      )}

      {(showSome.gender || showAll) && (
        <Box sx={{ pt: 1, width: '100%' }}>
          <ScrollableComponent autoFocus={autoFocusLabelName === 'I designate myself as'}>
            <FormControl
              error={errors.gender}
              fullWidth
              autoFocus={autoFocusLabelName === 'I designate myself as'}
            >
              <InputLabel id="select-gender-input-label">I designate myself as</InputLabel>
              <Select
                label="I designate myself as"
                labelId="select-gender-label"
                id="select-gender-id"
                value={form.gender}
                onChange={handleTextField('gender')}
                data-test="confirm-gender-input"
              >
                <MenuItem data-test="confirm-gender-female" value={'Woman/Girl'}>
                  Woman
                </MenuItem>
                <MenuItem data-test="confirm-gender-male" value={'Man/Boy'}>
                  Man
                </MenuItem>
                <MenuItem data-test="confirm-gender-non-binary" value={'Non-Binary/Non-Conforming'}>
                  Non-Binary/Non-Conforming
                </MenuItem>
                <MenuItem data-test="confirm-gender-no-response" value={'Prefer not to respond'}>
                  Prefer not to respond
                </MenuItem>
              </Select>
            </FormControl>
          </ScrollableComponent>
        </Box>
      )}
    </Stack>
  )
}

const ImportantGuidelinesAndExpectations = ({ open, handleClose, onDismiss }) => {
  return (
    <Dialog onClose={handleClose} open={open} maxWidth="md">
      <DialogTitle>
        <Typography variant="h5">Unyte Client Connections</Typography>
      </DialogTitle>

      <DialogContent dividers>
        <LearnMore />
      </DialogContent>
      <DialogActions>
        <Button onClick={onDismiss} color="secondary">
          Dismiss
        </Button>
      </DialogActions>
    </Dialog>
  )
}

/**
 * This is a modal that appears when a user has selected client connection but has deleted required fields
 */
const ConfirmSave = ({ open, form = {}, handleSave, setOpen }) => {
  const fieldNames = [
    {
      key: 'modalities',
      type: 'array',
      value: 'Modalities Used',
    },
    {
      key: 'clinicalSpecialties',
      type: 'array',
      value: 'Clinical Specialties',
    },
    {
      key: 'population',
      type: 'array',
      value: 'Population',
    },
    {
      key: 'languagesSpoken',
      type: 'array',
      value: 'Languages Spoken',
    },
    { key: 'acceptInsurance', type: 'string', value: 'Accept Insurance' },
    { key: 'gender', type: 'string', value: 'Gender' },
  ]
    .filter(({ key, type }) => {
      if (type === 'array') {
        return !form[key].length
      } else {
        // only thing left is string
        return !form[key]
      }
    })
    .map(({ value }) => value)

  // if we're here we know this is invalid so set state to false
  const handleContinue = async () => {
    await handleSave({ openToNewClients: false })
    await setOpen(false)
  }

  const handleCancel = () => {
    setOpen(false)
  }

  return (
    <Dialog open={open} onClose={handleCancel} maxWidth="md">
      <DialogTitleWithClose onClose={handleCancel}>Confirm Update</DialogTitleWithClose>
      <Divider />

      <DialogContent>
        <Typography variant="body2" gutterBottom>
          You've deleted <span className="font-medium">{fieldNames?.join(', ')}</span> from your
          professional information, which is required to maintain your enrollment in Unyte Client
          Connections. The removal of this information will result in your unenrollment from the
          program.
        </Typography>
        <Typography variant="body2" mt={2}>
          Do you want to continue?
        </Typography>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button onClick={handleCancel} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleContinue} color="primary">
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const UnavailableModal = ({ open, handleClose = () => {} }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitleWithClose onClose={handleClose}>Oops! </DialogTitleWithClose>
      <DialogContent>
        <Typography variant="body2">
          Client Connections is unavailable due to your subscription status. To enable this feature,
          make sure you have an active subscription
        </Typography>
      </DialogContent>
    </Dialog>
  )
}
