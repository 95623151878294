import React from 'react'
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  Typography,
  SxProps,
} from '@mui/material'

interface CertificationCardProps {
  title: string | React.ReactNode
  content?: React.ReactNode
  action?: React.ReactNode
  cardHeaderSx?: SxProps
}

export const CertificationCard: React.FC<CertificationCardProps> = ({
  title,
  content,
  children,
  cardHeaderSx = {
    '@media (max-width: 239px)': {
      height: 'auto',
    },
    '@media (min-width: 240px)': {
      height: '4rem', // Reset to auto or specify a different height above 240px
      mt: 2,
    },
  },
}) => {
  return (
    <Grid item xs={12} sm={4} alignItems="stretch">
      <Card elevation={0} variant="outlined" sx={{ height: '100%', pb: 4 }}>
        <Stack justifyContent="space-between" height="100%" spacing={1}>
          <div>
            <CardHeader title={title} sx={cardHeaderSx} />
            <CardContent sx={{ paddingBottom: '100%' }}>
              <Typography variant="body1" component="p">
                {content}
              </Typography>
            </CardContent>
          </div>
          <CardActions>{children}</CardActions>
        </Stack>
      </Card>
    </Grid>
  )
}
