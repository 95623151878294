import { gql, useQuery } from 'utils/apollo'
import get from 'lodash/get'
import { useSelector, useDispatch } from 'react-redux'
import { setAllProductAssessments } from 'store/modules/assessments'
import ROLES from 'utils/constants/roles'

const GET_ASSESSMENTS_METADATA = gql`
  query getAssessmentsMetadata($filter: FilterProductEventsInput) {
    getProductEvents(filter: $filter) {
      createdAt
      id
      productId
      metadata
    }
  }
`

/**
 * Get all assessments in product events
 * NOTE: some assessments don't have variants!
 */
export default function useGetAssessments({ productEventId }) {
  const dispatch = useDispatch()
  const productAssessments = useSelector((state) => state.assessments.productAssessments)
  const { hasRrpProducts, hasSspProducts } = useSelector((state) => state.ff)

  const roles = useSelector((state) => get(state, 'auth.user.roles', []))
  const isClient = roles?.some((role) => ROLES.CLIENT_ROLES.includes(role))

  const { loading } = useQuery(GET_ASSESSMENTS_METADATA, {
    variables: {
      filter: {
        types: 'assessment',
      },
    },
    onCompleted: (data) => {
      // 1. filter for SSP
      // 2. filter for RRP
      const productEvents = get(data, 'getProductEvents', [])
      const filterProductEvents = productEvents
        .filter(
          (productEvent) =>
            // if you don't have SSP, don't show intakeform
            (get(productEvent, 'metadata.isIntakeForm', false) && hasSspProducts) ||
            !get(productEvent, 'metadata.isIntakeForm', false)
        )
        .filter((productEvent) => {
          // don't show NQ to providers without RRP
          // RRP can only see certain assessments
          if (hasRrpProducts && !hasSspProducts) {
            return get(productEvent, 'metadata.isRrpAssessment')
          } else {
            return !get(productEvent, 'metadata.isNQ')
          }
        })
      if (isClient) {
        dispatch(setAllProductAssessments(productEvents))
      } else {
        dispatch(setAllProductAssessments(filterProductEvents))
      }
    },
    fetchPolicy: 'cache-and-network',
    skip: !!productAssessments.length,
  })
  const selectedAssessment = get(
    productAssessments.filter(({ id }) => id === productEventId),
    '[0]',
    {}
  )

  const assessmentNames = productAssessments?.reduce(
    (accumulator, { id, metadata: { assessmentType, variant } }) => ({
      ...accumulator,
      [id]: variant ? `${assessmentType} - ${variant}` : assessmentType,
    }),
    {}
  )
  return { loading, selectedAssessment, assessmentNames }
}
