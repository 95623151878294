/**
 * This component shows detailed information containing all questions asked
 * response is optimized for smallest desktop screen size: 1280x720
 */
import React from 'react'
import {
  Box,
  Paper as MuiPaper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from 'components'
import get from 'lodash/get'
import { isEmpty } from 'utils/isEmpty'
import moment from 'moment'
import { parseMetadata } from './utils/parse-metadata'
import { PrintHeader } from 'components/assessments/print-header'

const Paper = (props) => {
  return <MuiPaper variant="outlined" {...props} />
}

const catsTransform = (a, index, numberOfItems) => {
  // last 5 questions have values flipped
  if (a === 0 || a === '0') {
    return 'Yes'
  } else if (a === 1 || a === '1') {
    return 'No'
  }
  return '-'
}

// need to put state logic in parent component due to infinite loop from recharts
export default function ResponseTable({
  completedAssessments,
  metadata,
  isPrintMode,
  clientName,
  assessmentName,
}) {
  let questionsMap = {}
  let newQuestions = {}
  let rateValues = {}
  let finalAnswers = {}
  // this currently does not work with subscales..
  let newRateValues = {}

  // response is sorted by latest first
  const filteredCompletedData = completedAssessments.sort((a, b) => {
    const dateA = a.data.completedAt ? new Date(a.data.completedAt) : null
    const dateB = b.data.completedAt ? new Date(b.data.completedAt) : null

    if (dateA === null) return -1
    if (dateB === null) return 1
    return dateB - dateA
  })

  // transform questions into object
  get(metadata, 'survey.pages', []).forEach(({ elements }) =>
    elements.forEach(({ elements }) => {
      const title = get(elements, '[0].title')
      const name = get(elements, '[0].name')

      questionsMap[name] = title
      newQuestions[title] = 0
    })
  )

  // transform rates into object depending if survey has subscales
  const { subScales, isAIS, isCATS, catsScoring, numberOfItems } = parseMetadata(metadata)

  if (isEmpty(subScales) && !isAIS) {
    get(metadata, 'survey.pages[0].elements', []).forEach((element) => {
      element?.rateValues?.forEach(({ text, value }) => {
        rateValues[value] = text
        finalAnswers[text] = { ...newQuestions }
      })
    })
  } else {
    get(metadata, 'survey.pages[1].elements', []).forEach((element) => {
      element?.rateValues?.forEach(({ text, value }) => {
        rateValues[value] = text
        finalAnswers[text] = { ...newQuestions }
        // this is for AIS
        if (isAIS) {
          newRateValues[element.name] = { ...newRateValues[element.name], [value]: text }
        }
      })
    })
  }

  // add answers to final
  filteredCompletedData.forEach(({ data: { answers } }) => {
    if (answers) {
      Object.entries(answers).forEach(([question, answer]) => {
        if (typeof answer === 'number') {
          const expandedText = rateValues[answer]
          const expandedQuestion = questionsMap[question]
          if (finalAnswers[expandedText]) {
            finalAnswers[expandedText] = {
              ...finalAnswers[expandedText],
              [expandedQuestion]: finalAnswers[expandedText][expandedQuestion] + 1,
            }
          } else {
            finalAnswers[expandedText] = { ...finalAnswers[expandedText], [expandedQuestion]: 1 }
          }
        }
      })
    }
  })

  // get number of tables by
  const numberOfTables = Math.ceil(numberOfItems / 10)

  return (
    // we need to use a custom component for paper
    <Stack spacing={1} mx={isPrintMode ? 0 : 3} mt={isPrintMode ? 0 : 5}>
      {/* Normal layout */}
      {!isPrintMode && (
        <Typography variant="h5" pb={2}>
          Response
        </Typography>
      )}
      {!isPrintMode && (
        <TableContainer
          component={Paper}
          sx={{
            overflowX: 'auto',
          }}
        >
          <Table
            aria-label="response table"
            stickyHeader
            style={{ minWidth: '700px', backgroundColor: '#fafafa' }}
          >
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: 'var(--gray-50)',
                }}
              >
                <TableCell>Items</TableCell>
                {filteredCompletedData.map(({ data: { completedAt } }, index) => (
                  <TableCell key={index} sx={{ minWidth: '100px', zIndex: 0 }}>
                    {moment(completedAt).format('MMMM DD YYYY')}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(questionsMap)
                .filter(Boolean)
                .map(([key, question], index) => {
                  if (!index) return null
                  return (
                    <TableRow
                      key={key}
                      sx={{
                        backgroundColor: 'var(--gray-50)',
                        '&:nth-of-type(even)': {
                          backgroundColor: 'var(--white)',
                        },
                        pageBreakAfter: index % 12 === 11 ? 'always' : '',
                      }}
                    >
                      <TableCell
                        component="th"
                        style={{
                          top: 0,
                          left: 0,
                          zIndex: 2,
                          position: 'sticky',
                          width: '66%',
                        }}
                      >
                        {index}. {question}
                      </TableCell>
                      {filteredCompletedData.map(({ data: { answers } }, i) => {
                        // answer is '-' if we're at BBCSS

                        // we'll use let here..
                        let answer = answers[key] < 1000 && answers[key]
                        answer = !isCATS && (['0', 0].includes(answer) || !answer) ? '-' : answer
                        const isCATSAnswer = catsScoring.includes(key)
                          ? answer
                          : catsTransform(answer, index, numberOfItems)
                        return (
                          <TableCell key={i}>
                            {isCATS && <Typography variant="body1">{isCATSAnswer}</Typography>}
                            {!isCATS && <Typography variant="body1">{answer}</Typography>}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
              <TableRow
                sx={{
                  '&:nth-of-type(even)': {
                    backgroundColor: isPrintMode ? 'var(--gray-50)' : 'var(--white)',
                  },
                }}
              >
                <TableCell>
                  <Typography variant="h5">Total Score</Typography>
                </TableCell>
                {completedAssessments?.map((assessment, index) => {
                  const _score = get(assessment, 'data.answers.score.sum', 0)
                  return (
                    <TableCell key={index} align="left">
                      <Typography variant="h5">{_score}</Typography>
                    </TableCell>
                  )
                })}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Print layout */}
      {isPrintMode &&
        Array.from({ length: numberOfTables }, (_, aIndex) => (
          <>
            <PrintHeader
              clientName={clientName}
              assessmentName={assessmentName}
              pageCurrent={3 + aIndex}
              pageMax={numberOfTables + 3}
            />
            {aIndex === 0 && (
              <Typography variant="h3" className="print-h3" px={3}>
                Scoring Trend
              </Typography>
            )}
            <Box px={3}>
              <TableContainer
                component={Paper}
                sx={{
                  overflowX: 'auto',
                }}
              >
                <Table
                  key={`print-table-${aIndex}`}
                  aria-label="response table"
                  stickyHeader
                  style={{ minWidth: '700px', backgroundColor: '#fafafa' }}
                >
                  <TableHead>
                    <TableRow
                      sx={{
                        backgroundColor: 'var(--gray-50)',
                      }}
                    >
                      <TableCell>Items</TableCell>
                      {filteredCompletedData.map(({ data: { completedAt } }, index) => (
                        <TableCell key={index} sx={{ minWidth: '100px', zIndex: 0 }}>
                          {moment(completedAt).format('MMMM DD YYYY')}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.entries(questionsMap)
                      .slice(aIndex * 10, 10 * (aIndex + 1))
                      .map(([key, question], index) => (
                        <TableRow
                          key={key}
                          sx={{
                            backgroundColor: 'var(--gray-50)',
                            '&:nth-of-type(even)': {
                              backgroundColor: 'var(--white)',
                            },
                          }}
                        >
                          <TableCell
                            component="th"
                            style={{
                              top: 0,
                              left: 0,
                              zIndex: 2,
                              position: 'sticky',
                              width: '66%',
                            }}
                          >
                            {aIndex * 10 + index + 1}. {question}
                          </TableCell>
                          {filteredCompletedData.map(({ data: { answers } }, i) => {
                            // answer is '-' if we're at BBCSS

                            // we'll use let here..
                            let answer = answers[key] < 1000 && answers[key]
                            answer =
                              !isCATS && (['0', 0].includes(answer) || !answer) ? '-' : answer
                            const isCATSAnswer = catsScoring.includes(key)
                              ? answer
                              : catsTransform(answer, index, numberOfItems)
                            return (
                              <TableCell key={i}>
                                {isCATS && <Typography variant="body1">{isCATSAnswer}</Typography>}
                                {!isCATS && <Typography variant="body1">{answer}</Typography>}
                              </TableCell>
                            )
                          })}
                        </TableRow>
                      ))}
                    {aIndex === numberOfTables - 1 && (
                      <TableRow
                        sx={{
                          '&:nth-of-type(even)': {
                            backgroundColor: isPrintMode ? 'var(--gray-50)' : 'var(--white)',
                          },
                        }}
                      >
                        <TableCell>
                          <Typography variant="h5">Total Score</Typography>
                        </TableCell>
                        {completedAssessments?.map((assessment, index) => {
                          const _score = get(assessment, 'data.answers.score.sum', 0)
                          return (
                            <TableCell key={index} align="left">
                              <Typography variant="h5">{_score}</Typography>
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <div style={{ pageBreakAfter: 'always' }} />
          </>
        ))}
    </Stack>
  )
}
