import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import get from 'lodash/get'
import { useSnackbar } from 'notistack'

import {
  Typography,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
  Button,
  TermsOfUse,
  Grid,
  Divider,
} from 'components'
import makeStyles from '@mui/styles/makeStyles'
import { extendUser } from 'store/modules/auth'
import { gql, useMutation } from 'utils/apollo'
import { PROFESSIONAL_CREDENTIALS } from 'utils/constants/professionalCredentials'
import { useNavigate } from 'react-router'
import MainLayout from 'components/containers/main/Main'
import tabs from 'utils/constants/tabs'
import { includesSome } from 'utils/includes'
import ROLES from 'utils/constants/roles'
import CloseSnackbarAction from 'components/CloseSnackbarAction'

const UPDATE_USER = gql`
  mutation updateUser($user: UpdateUserInput!) {
    updateUser(user: $user) {
      id
      email
      firstName
      lastName
      createdAt
      isSuspended
      address1
      address2
      city
      state
      country
      phone
      dob
      professionalCredentials
      otherProfessionalCredentials
      zip
    }
  }
`

const useStyles = makeStyles({
  formControl: { width: '100%' },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  professionalCredentials: {
    fontWeight: 400,
    fontSize: '1.25rem',
  },
})

export default function EditContact() {
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const id = useSelector((state) => get(state, 'auth.user.id'))
  const getProfessionalCredentials = useSelector((state) =>
    get(state, 'auth.user.professionalCredentials', null)
  )
  const [updateUser] = useMutation(UPDATE_USER)
  const [agreement, setAgreement] = useState(false)
  const [professionalCredentials, setProfessionalCredentials] = useState()

  const classes = useStyles()

  const onChange = (event) => {
    setProfessionalCredentials(event.target.value)
  }

  const onSubmit = async (event) => {
    event.preventDefault()
    try {
      const res = await updateUser({ variables: { user: { id, professionalCredentials } } })
      dispatch(extendUser(res.data.updateUser))
      setAgreement(false)
      enqueueSnackbar('Update successful', { variant: 'success', action: CloseSnackbarAction })
      navigate(-1)
    } catch (error) {
      console.error('on submit professional credentials', error)
    }
  }

  return (
    <ShowProviderProfileWrapper>
      <Grid container spacing={2} className="px-5">
        <Grid item md={7}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className="py-4"
          >
            <div className={classes.container}>
              <Typography variant="h4" className="pr-3">
                Current Professional Credentials
              </Typography>
              <Typography variant="h5" className="pt-3">
                {['Other', 'Other Credentials'].includes(getProfessionalCredentials)
                  ? 'Other credentials which have been approved by Unyte Health'
                  : getProfessionalCredentials}
              </Typography>
            </div>
          </Grid>
          <Divider />
          {getProfessionalCredentials !== 'Other' && (
            <form
              data-test="change-professional-credentials-form"
              onSubmit={onSubmit}
              className="py-5"
            >
              <FormControl className={classes.formControl}>
                <InputLabel id="select-professional-credentials-input-label">
                  Change your professional credentials
                </InputLabel>
                <Select
                  label="Change your professional credentials"
                  labelId="select-professional-credentials-label"
                  id="select-professional-credentials"
                  value={professionalCredentials || ''}
                  onChange={onChange}
                >
                  {PROFESSIONAL_CREDENTIALS.map((value, index) => {
                    if (value === 'Other') {
                      return null
                    } else {
                      return (
                        <MenuItem key={`pc${index}`} value={value}>
                          {value}
                        </MenuItem>
                      )
                    }
                  })}
                </Select>
              </FormControl>
              <div className="my-5">
                <TermsOfUse checked={agreement} onChange={setAgreement} />
              </div>
              <Grid className="mt-5" container justifyContent="flex-end">
                <Button type="submit" disabled={!agreement} color="primary" variant="contained">
                  Change Professional Credentials
                </Button>
              </Grid>
            </form>
          )}
        </Grid>
      </Grid>
    </ShowProviderProfileWrapper>
  )
}

// TODO: delete this after FF removal
export const ShowProviderProfileWrapper = ({ children, title = 'My Account' }) => {
  const currentUserRoles = useSelector((state) => get(state, 'auth.user.roles', []))
  const isProvider = includesSome(currentUserRoles, [ROLES.PROVIDER, ROLES.PROVIDER_ALL_CLIENTS])
  const isClient = includesSome(currentUserRoles, [ROLES.CLIENT])
  const isBilling = includesSome(currentUserRoles, [ROLES.BILLING])
  const TABS = tabs.filter(({ isEnabled }) =>
    isEnabled({
      isProvider,
      isBilling,
    })
  )
  if (!isClient) {
    return <>{children}</>
  } else {
    return (
      <MainLayout title={title} tabs={TABS}>
        {children}
      </MainLayout>
    )
  }
}
