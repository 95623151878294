/**
 * This hook will be used to get ALL sessions
 *
 * Key features:
 * - limits query to 200 and refetches
 * - used in:
 *   - assessments
 *   - if user has ssp / ils sessions
 *   - client dashboard
 */
import { useEffect, useState } from 'react'
import get from 'lodash/get'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery, gql } from 'utils/apollo'
import { compareAsc, endOfDay, format, formatISO, sub } from 'date-fns'
import { useGetProducts } from './useGetProducts'

// redux
import { setAssessmentSessions, setProductEvents } from 'store/modules/assessments'
import { setAssessmentsAndSessions } from 'store/modules/sessions'
import { setSession as setClientSessions } from 'store/modules/clients'
import roles from 'utils/constants/roles'
import { ASSESSMENT_STATUS } from 'utils/constants/assessmentStatus'

const FETCH_LIMIT_DASHBOARD = 20
const FETCH_LIMIT = 100

const GET_PRODUCT_EVENTS = gql`
  query GetProductEventsForSessions($filter: FilterProductEventsInput) {
    getProductEvents(filter: $filter) {
      id
      productId
      title
      metadata
    }
  }
`

const GET_SINGLE_SESSIONS = gql`
  query DashboardGetSingleSessions(
    $filter: FilterSessionsInput
    $limit: Int
    $skipOrdering: Boolean
  ) {
    getSessions(filter: $filter, limit: $limit, skipOrdering: $skipOrdering) {
      id
    }
  }
`

const GET_ALL_SESSIONS = gql`
  query getAllSessionsHook($filter: FilterSessionsInput, $offset: Int, $limit: Int) {
    getSessions(filter: $filter, offset: $offset, limit: $limit) {
      createdAt
      metadata
      data
      id
      product {
        id
        name
      }
      productId
      productEvent {
        metadata
        title
      }
      productEventId
      startedAt
      type
      totalTime
      totalInteractionTime
      user {
        id
        fullName
        email
        gender
        country
        birthYear
      }
      userId
      updatedAt
    }
  }
`

/**
 * ProductEventId, id and user.id is for another dashboard card
 */
const GET_SENT_ASSESSMENTS = gql`
  query DashboardSentAssessments($filter: FilterSessionsInput, $offset: Int, $limit: Int) {
    getSessions(filter: $filter, offset: $offset, limit: $limit) {
      type
      data
      productId
      productEventId
      id
      user {
        id
        fullName
        email
        gender
        country
        birthYear
      }
      updatedAt
    }
  }
`

const GET_ASSESSMENTS = gql`
  query AssessmentsGetSessions(
    $filter: FilterSessionsInput
    $offset: Int
    $limit: Int
    $sort: [[String]]
  ) {
    getSessions(filter: $filter, offset: $offset, limit: $limit, sort: $sort) {
      type
      data
      productId
      productEventId
      id
      user {
        id
        fullName
        email
        gender
        country
        birthYear
      }
      updatedAt
      createdAt
    }
  }
`

const GET_ASSESSMENTS_DASHBOARD = gql`
  query AssessmentsGetSessionsDashboard($filter: FilterSessionsInput, $offset: Int, $limit: Int) {
    getSessions(filter: $filter, offset: $offset, limit: $limit) {
      type
      data
      productId
      productEventId
      id
      user {
        id
        fullName
        email
        gender
        country
        birthYear
      }
      updatedAt
    }
  }
`

/**
 * This query is only used in dashboard for providers to check how many assessments are sent and
 *
 * NOTES:
 * - we must use `cache-and-network` if you want to call fetchMore
 * - this is only being called in dashboard, but is not needed when a provider views a client's dashboard
 */
export const useGetSentAssessments = ({ skip: _skip, isProvider }) => {
  const [offset, setOffset] = useState(0)
  const [sessions, setSessions] = useState([])
  const dispatch = useDispatch()
  const { isSent, isCompleted } = ASSESSMENT_STATUS

  // only filter for 7 days if we are the provider
  const updatedAt = isProvider
    ? {
        gtEq: formatISO(endOfDay(sub(new Date(), { days: 7 }))),
      }
    : {}

  const { loading, fetchMore } = useQuery(GET_SENT_ASSESSMENTS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      filter: {
        types: ['answers'],
        updatedAt,
      },
      offset,
      limit: FETCH_LIMIT_DASHBOARD,
    },
    skip: _skip || offset % FETCH_LIMIT_DASHBOARD !== 0,
    onCompleted: (data) => {
      const getSessions = get(data, 'getSessions', [])
      const allSessions = [...sessions, ...getSessions]

      setOffset(offset + getSessions.length)
      setSessions(allSessions)

      // we are at the end of the query, store into redux..
      if (getSessions.length === FETCH_LIMIT_DASHBOARD) {
        fetchMore({
          filter: {
            types: ['answers'],
          },
          limit: FETCH_LIMIT_DASHBOARD,
          offset,
        })
      } else {
        const sentAssessments = allSessions.filter(
          ({ type, data }) => type === 'answers' && isSent(data?.status)
        )
        const completedAssessments = allSessions.filter(
          ({ type, data }) => type === 'answers' && isCompleted(data?.status)
        )
        dispatch(
          setAssessmentsAndSessions({
            sentAssessments,
            completedAssessments,
          })
        )
      }
    },
  })

  return {
    loading,
  }
}

/**
 * This function is used in dashboard to know if user has ssp / ils sessions
 */
export const useGetSingleSessions = ({ skip }) => {
  const dispatch = useDispatch()
  const { loading: loadingSsp, previousData: previousSspData } = useQuery(GET_SINGLE_SESSIONS, {
    skip,
    fetchPolicy: 'cache-and-network',
    variables: { filter: { types: ['ssp'] }, limit: 1, skipOrdering: true },
    onCompleted: (data) => {
      const getSessions = get(data, 'getSessions', [])
      if (getSessions.length) {
        dispatch(
          setAssessmentsAndSessions({
            hasSspSessions: true,
            hasSessions: true,
          })
        )
      }
    },
  })
  const { loading: loadingRrp, previousData: previousRrpData } = useQuery(GET_SINGLE_SESSIONS, {
    skip,
    fetchPolicy: 'cache-and-network',
    variables: { filter: { types: ['rrp'] }, limit: 1, skipOrdering: true },
    onCompleted: (data) => {
      const getSessions = get(data, 'getSessions', [])
      if (getSessions.length) {
        dispatch(
          setAssessmentsAndSessions({
            hasRrpSessions: true,
            hasSessions: true,
          })
        )
      }
    },
  })
  const { loading: loadingFocus, previousData: previousFocusData } = useQuery(GET_SINGLE_SESSIONS, {
    skip,
    fetchPolicy: 'cache-and-network',
    variables: { filter: { types: ['focus'] }, limit: 1, skipOrdering: true },
    onCompleted: (data) => {
      const getSessions = get(data, 'getSessions', [])
      if (getSessions.length) {
        dispatch(
          setAssessmentsAndSessions({
            hasFocusSessions: true,
            hasSessions: true,
          })
        )
      }
    },
  })
  const { loading: loadingAssessments, previousData: previousAssessmentData } = useQuery(
    GET_SINGLE_SESSIONS,
    {
      skip,
      fetchPolicy: 'cache-and-network',
      variables: { filter: { types: ['answers'] }, limit: 1, skipOrdering: true },
      onCompleted: (data) => {
        const getSessions = get(data, 'getSessions', [])
        if (getSessions.length) {
          dispatch(
            setAssessmentsAndSessions({
              hasAssessmentSessions: true,
            })
          )
        }
      },
    }
  )
  return {
    loading:
      (loadingSsp || loadingRrp || loadingFocus || loadingAssessments) &&
      !(previousSspData && previousRrpData && previousFocusData && previousAssessmentData),
  }
}

// we don't need to call get product events because that is already called in parent component
export const useGetAssessmentSessions = () => {
  const dispatch = useDispatch()
  const [offset, setOffset] = useState(0)
  const [sessions, setSessions] = useState([])
  const [firstLoadingComplete, setFirstLoadingComplete] = useState(false)
  const [finishedLoading, setFinishedLoading] = useState(false)

  const { refetch } = useQuery(GET_ASSESSMENTS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      filter: { types: ['answers'] },
      limit: FETCH_LIMIT,
      offset: offset,
      sort: [['updatedAt', 'DESC']],
    },
    skip: offset % FETCH_LIMIT !== 0,
    onCompleted: (data) => {
      const seenIds = new Set()

      const getSessions = get(data, 'getSessions', [])
      const allSessions = [...sessions, ...getSessions].filter((element) => {
        if (seenIds.has(element.id)) {
          return false
        } else {
          seenIds.add(element.id)
          return true
        }
      })

      setOffset(offset + getSessions.length)
      setSessions(allSessions)
      setFirstLoadingComplete(true)

      // we are at the end of the query, store into redux..
      if (!getSessions.length || getSessions.length % FETCH_LIMIT !== 0) {
        // store in clients sessions redux
        dispatch(setAssessmentSessions(allSessions))
        setFinishedLoading(true)

        // optimistic view - continue loading in background..
      } else if (getSessions.length === FETCH_LIMIT) {
        refetch({ offset, limit: FETCH_LIMIT, filter: { types: ['answers'] } })
        if (offset === 0) {
          dispatch(setAssessmentSessions(allSessions))
        }
      }
    },
  })

  const refetchAll = async () => {
    try {
      setSessions([])
      setOffset(0)
      setFirstLoadingComplete(false)
      setFinishedLoading(false)
      refetch({ offset: 0, limit: FETCH_LIMIT, types: ['answers'] })
    } finally {
      setFirstLoadingComplete(true)
      setFinishedLoading(true)
    }
  }

  return {
    refetch: refetchAll,
    loading: !firstLoadingComplete,
    sessions,
    firstLoadingComplete,
    finishedLoading,
  }
}

// For dashboard, we only want to get assessments done within the past month
export const useGetAssessmentSessionsForDashboard = ({ skip, gtEq }) => {
  const dispatch = useDispatch()
  useQuery(GET_ASSESSMENTS_DASHBOARD, {
    fetchPolicy: 'cache-and-network',
    variables: {
      filter: { types: ['answers'], updatedAt: { gtEq } },
    },
    skip,
    onCompleted: (data) => {
      const getSessions = get(data, 'getSessions', [])
      dispatch(setAssessmentSessions(getSessions))
    },
  })
}

/**
 * Get all sessions for userId, if no userId is provided
 *   then we'll use currently authenticated userid
 *
 * type: ['assessment', 'client', 'user']
 *
 */
export const useGetSessions = ({ userIds, filter = {}, refreshFilter = true }) => {
  const dispatch = useDispatch()
  const [offset, setOffset] = useState(0)
  const [sessions, setSessions] = useState([])
  const [firstLoadingComplete, setFirstLoadingComplete] = useState(false)
  const [finishedLoading, setFinishedLoading] = useState(false)
  const productEvents = useSelector((state) => state.assessments.productEvents)
  const currentUserRoles = useSelector((state) => get(state, 'auth.user.roles', []))
  const isProvider = currentUserRoles.some((role) => roles.PROVIDER_ROLES.includes(role))

  // we need to refresh query every time filter updates in client dashboard..
  useEffect(() => {
    if (refreshFilter) {
      setSessions([])
      setOffset(0)
    }
    // eslint-disable-next-line
  }, [filter])

  const userProducts = useSelector((state) => state.seats.products)
  const { data: allProducts } = useGetProducts()

  // get all product events
  useQuery(GET_PRODUCT_EVENTS, {
    fetchPolicy: 'cache-and-network',
    skip: !!productEvents.length,
    onCompleted: (data) => {
      dispatch(setProductEvents(get(data, 'getProductEvents', [])))
    },
  })

  const parsedUserIds = userIds?.filter(Boolean)?.map((id) => parseInt(id))
  const fetchMoreVariables = ({ offset, filter }) => {
    const gtEq = filter?.startedAt?.gtEq
    const ltEq = filter?.startedAt?.ltEq
      ? formatISO(endOfDay(new Date(filter.startedAt.ltEq)))
      : null
    const startedAt = gtEq || ltEq ? { gtEq, ltEq } : undefined
    return {
      filter: {
        // NOTE: This should be array of categories of available products
        //   not including ssp_preview and focus_preview
        types: ['ssp', 'rrp', 'focus'],
        productIds: filter?.productId?.length ? filter.productIds : undefined,
        startedAt,
        userIds: parsedUserIds?.length > 0 ? parsedUserIds : undefined,
        ...filter,
      },
      limit: FETCH_LIMIT,
      offset: offset,
    }
  }

  const { fetchMore } = useQuery(GET_ALL_SESSIONS, {
    fetchPolicy: 'cache-and-network',
    variables: fetchMoreVariables({ offset, filter }),
    skip:
      (!productEvents.length && isProvider) || // only providers can query product events..
      (firstLoadingComplete && sessions.length % FETCH_LIMIT !== 0) ||
      !allProducts.length ||
      (!userProducts.length && isProvider), // client can not have sessions
    onCompleted: (data) => {
      const getSessions = get(data, 'getSessions', [])
      const allSessions = [...sessions, ...getSessions]
      const transformSessions = allSessions
        .sort((a, b) => {
          return compareAsc(new Date(b.startedAt), new Date(a.startedAt))
        })
        .map((data) => {
          const product = allProducts.find(({ id }) => id === data.productId)
          // we will only show the event Name if user still has product..
          const userHasProduct = userProducts.find(({ id }) => id === data.productId)
          const productEvent =
            userHasProduct && productEvents.find(({ id }) => data.productEventId === id)
          return {
            ...data,
            productName: get(product, 'name', ''),
            image: get(product, 'metadata.sessionIconUrl', ''),
            eventName: get(productEvent, 'title', ''),
            x: format(new Date(data.startedAt), 'MMM d'),
            y: get(data, 'data.interactiveMeditation.avgCoherence', 0),
            y2: get(data, 'totalInteractionTime', 0),
            color: '#62c3d9',
            metadata: get(product, 'metadata', {}),
          }
        })

      setOffset(offset + getSessions.length)
      setSessions(allSessions)
      setFirstLoadingComplete(true)

      // we are at the end of the query, store into redux..
      if (!getSessions.length || getSessions.length % FETCH_LIMIT !== 0) {
        dispatch(setClientSessions(transformSessions))
        setFinishedLoading(true)

        // optimistic view - continue loading in background..
      } else if (getSessions.length === FETCH_LIMIT) {
        fetchMore(fetchMoreVariables({ offset, filter }))
        if (offset === 0) {
          dispatch(setClientSessions(transformSessions))
        }
      }
    },
  })

  const refetchAll = () => {
    setSessions([])
    setOffset(0)
    setFirstLoadingComplete(false)
    setFinishedLoading(false)
    fetchMore(fetchMoreVariables({ offset, filter }))
  }
  return {
    refetch: refetchAll,
    loading:
      !firstLoadingComplete &&
      !(
        !productEvents.length ||
        (!allProducts.length && !userProducts.length) ||
        (firstLoadingComplete && sessions.length % FETCH_LIMIT !== 0)
      ),
    sessions,
    products: allProducts,
    firstLoadingComplete: firstLoadingComplete,
    finishedLoading,
  }
}
