import { gql, useQuery } from 'utils/apollo'
import get from 'lodash/get'
import { TEMPORARY_SSP_SUBSCRIPTION_SKUS } from 'utils/constants/prices'
import { ACTIVE_SUBSCRIPTION_STATUSES } from 'utils/constants/subscriptions'

const GET_PAYMENT_INFO = gql`
  query getPaymentInfo {
    getPaymentInfo
  }
`
export default () => {
  const { data, ...rest } = useQuery(GET_PAYMENT_INFO, { fetchPolicy: 'network-only' })

  const subscription = get(data, 'getPaymentInfo.subscriptions', [])
  const earliestStartDate = subscription.reduce(
    (earliest, { startDate }) =>
      Date.parse(earliest) < Date.parse(startDate) ? earliest : startDate,
    new Date().toISOString()
  )

  const activeSubscriptions = get(data, 'getPaymentInfo.subscriptions', []).filter(
    ({ status, sku }) =>
      ACTIVE_SUBSCRIPTION_STATUSES.includes(status) &&
      !TEMPORARY_SSP_SUBSCRIPTION_SKUS.includes(sku)
  )

  const charges = get(data, 'getPaymentInfo.charges', [])
  const unpaidInvoices = get(data, 'getPaymentInfo.unpaidInvoices', [])

  return {
    ...rest,
    earliestStartDate,
    activeSubscriptions,
    charges,
    unpaidInvoices,
  }
}
