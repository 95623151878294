import React, { useEffect, useState } from 'react'
import MainLayout from 'components/containers/main/Main'
import { useNavigate } from 'react-router'
import {
  Typography,
  Box,
  TextField,
  Button,
  Stack,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
} from 'components'
import { gql, useMutation } from '@apollo/client'
import { useForm, Controller } from 'react-hook-form'
import { DashboardBanner as Banner } from 'views/dashboard/components/banner'
import { themeDefinition } from 'utils/theme/Theme'
import MarkunreadIcon from '@mui/icons-material/Markunread'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'
import LockIcon from '@mui/icons-material/Lock'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import { IGNORED_SUBSCRIPTION_SKUS } from 'views/community/utils/canAccessCommunity'
import roles from 'utils/constants/roles'

const colors = themeDefinition.palette

const CREATE_REFERRAL = gql`
  mutation createReferral($referral: CreateReferralInput!) {
    createReferral(referral: $referral) {
      firstName
      lastName
      email
    }
  }
`

const OrderedList = ({ index, text }) => (
  <Stack direction="row" spacing={2} pb={2}>
    <Box
      sx={{
        width: '45px',
        height: '45px',
        backgroundColor: colors.pink.dark,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
      }}
    >
      <Typography
        sx={{
          color: 'white',
          fontWeight: 'bold',
          fontSize: '24px',
          lineHeight: '1',
        }}
      >
        {index}
      </Typography>
    </Box>
    <Typography sx={{ textAlign: 'justify' }}>{text}</Typography>
  </Stack>
)

const ReferralSubmittedPage = ({ onSubmit }) => (
  <Stack direction="column" pt={5} px={3} width="60%">
    <Typography pb={2} pl={2} variant="h5" color={colors.pink.dark}>
      Thanks for Sharing the Gift of Nervous System Health!
    </Typography>
    <Typography pl={2} color="primary">
      Here’s what happens next:
    </Typography>
    <List>
      <ListItem>
        <ListItemIcon>
          <MarkunreadIcon />
        </ListItemIcon>
        <ListItemText
          primary="We’ll Reach Out to Your Referral"
          secondary="If your friend or colleague is not a current Unyte user or has not been referred before, they will get an email invitation to explore how Unyte’s tools can transform their practice and their clients’ lives. We’ll let them know you referred them, and if they subscribe, they’ll enjoy a free month thanks to you!"
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <EmojiEventsIcon />
        </ListItemIcon>
        <ListItemText
          primary="You’ll Be Notified"
          secondary="When your referral subscribes to an annual plan, you’ll both get a free month as our thank-you!"
        />
      </ListItem>
    </List>
    <Button
      onClick={() => onSubmit(false)}
      aria-label="Refer Another Friend"
      variant="contained"
      sx={{
        ml: 2,
        alignSelf: 'flex-start',
        backgroundColor: colors.pink.dark,
      }}
    >
      <Typography color="white">Refer Another Friend</Typography>
    </Button>
  </Stack>
)

const CustomTextField = React.forwardRef((props, ref) => <TextField {...props} inputRef={ref} />)

const ReferralForm = ({ control, handleSubmit, onSubmit, loading, formFields, error }) => (
  <Box
    component="form"
    onSubmit={handleSubmit(onSubmit)}
    p={3}
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 1,
      backgroundColor: colors.mint.main,
      borderRadius: 1,
    }}
  >
    {formFields.map(({ key, label, rules }) => (
      <Controller
        key={key}
        name={key}
        control={control}
        rules={rules}
        render={({ field, fieldState: { error } }) => (
          <CustomTextField
            {...field}
            label={label}
            type={key}
            helperText={error && error.message}
            error={!!error}
          />
        )}
      />
    ))}
    <Stack direction="row" spacing={1} color={colors.gray.medium}>
      <LockIcon sx={{ alignSelf: 'center' }} />
      <Typography sx={{ fontSize: '11px', fontStyle: 'italic' }}>
        We respect your privacy. Your friend’s information will only be used for this referral; no
        spam, no sharing.
      </Typography>
    </Stack>
    <Button
      type="submit"
      variant="contained"
      sx={{ backgroundColor: colors.pink.dark }}
      disabled={loading}
    >
      <Typography color="white">{loading ? 'Submitting...' : 'Refer'}</Typography>
    </Button>

    {error && (
      <Typography variant="body2" color="error">
        {error}
      </Typography>
    )}
  </Box>
)

export default function ReferralProgram() {
  const navigate = useNavigate()

  const [isSubmitted, setIsSubmitted] = useState(false)
  const [error, setError] = useState(null)
  const [createReferral, { loading }] = useMutation(CREATE_REFERRAL)
  const { control, handleSubmit, reset } = useForm({
    defaultValues: { firstName: '', lastName: '', email: '' },
    mode: 'onChange',
    criteriaMode: 'all',
  })

  const formFields = [
    {
      key: 'firstName',
      label: <Typography variant="body2">Friend/Colleagues' First Name</Typography>,
      rules: {
        required: 'First name is required',
        maxLength: { value: 100, message: 'First name must be no longer than 100 characters' },
      },
    },
    {
      key: 'lastName',
      label: <Typography variant="body2">Friend/Colleagues' Last Name</Typography>,
      rules: {
        required: 'Last name is required',
        maxLength: { value: 100, message: 'Last name must be no longer than 100 characters' },
      },
    },
    {
      key: 'email',
      label: <Typography variant="body2">Friend/Colleagues' Email</Typography>,
      rules: {
        required: 'Email is required',
        pattern: { value: /^\S+@\S+\.\S+$/, message: 'Email format is invalid' },
      },
    },
  ]

  const onSubmit = async (formData) => {
    try {
      await createReferral({ variables: { referral: formData } })
      reset()
      setError(null)
      setIsSubmitted(true)
    } catch (err) {
      console.log('Submission Error:', err.message)
      setError(err.message)
    }
  }

  const hasClientRole = useSelector((state) => get(state, 'auth.user.roles', [])).includes(
    roles.CLIENT
  )
  const organizationProductsStatus = useSelector((state) => get(state, 'organization.products', {}))

  const showReferralPage =
    Object.keys(organizationProductsStatus).length > 0 && // Ensure the organization has products
    !Object.keys(organizationProductsStatus).some(
      (key) => IGNORED_SUBSCRIPTION_SKUS.includes(key) // If any product is in the ignored list, return false
    ) &&
    Object.keys(organizationProductsStatus).some((key) => {
      const status = organizationProductsStatus[key]?.status
      return ['paid', 'past_due', 'pending_certification'].includes(status) // At least one product must have a valid status
    }) &&
    !hasClientRole // Not a client

  useEffect(() => {
    if (!showReferralPage) {
      navigate('/')
    }
    // eslint-disable-next-line
  }, [])

  return (
    <MainLayout title="Unyte Provider Referral Program">
      <Banner
        title="Share the Power of Nervous System Health"
        optionalInfo="Share the benefits of nervous system regulation with your friends and colleagues, while earning rewards yourself."
        backgroundColor={colors.mint.main}
        imageSrc="/images/referral/referral-banner.png"
      />
      {isSubmitted ? (
        <ReferralSubmittedPage onSubmit={setIsSubmitted} />
      ) : (
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box pt={3} px={5}>
              <Typography
                component="div"
                sx={{
                  color: colors.pink.dark,
                  fontSize: '3rem',
                  fontWeight: 500,
                  pb: 5,
                  lineHeight: 1,
                }}
              >
                <div>Give a Month,</div>
                <div>Get a Month!</div>
              </Typography>
              <OrderedList
                index={1}
                text="Submit your friend or colleague’s name and email. We’ll send them an email, introducing them to Unyte and explaining the process."
              />
              <OrderedList
                index={2}
                text={
                  <>
                    If your referral becomes a Unyte Provider with an active annual subscription,
                    you’ll both receive a{' '}
                    <span style={{ color: colors.pink.dark }}>
                      <b>one month subscription credit</b>
                    </span>
                    !
                  </>
                }
              />
              <Typography pt={3} sx={{ color: colors.pink.dark, fontSize: '1.2rem' }}>
                Unlimited referrals, more free months, lasting impact.
              </Typography>
              {/* Knowledge based article */}
              <Typography>
                <a
                  href="https://support.unyte.com/details-on-unytes-provider-referral-program"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: colors.text.main }}
                >
                  More details
                </a>
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Stack direction="column" pt={5} px={5} width={{ xs: '100%', md: '450px' }}>
              <ReferralForm
                control={control}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                loading={loading}
                formFields={formFields}
                error={error}
              />
              <Typography sx={{ fontSize: '14px', p: '20px', textAlign: 'justify' }}>
                *Terms, conditions and exclusions apply. Referrals must be{' '}
                <span style={{ color: colors.pink.dark }}>first-time customers</span> who have not
                purchased from Unyte in the past. Rewards are eligible for new{' '}
                <span style={{ color: colors.pink.dark }}>annual subscription plans</span> only. A
                new subscriber can only receive{' '}
                <span style={{ color: colors.pink.dark }}>one referral credit</span>.
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      )}
    </MainLayout>
  )
}
