/**
 * PAGINATION LAYOUT WRAPPER
 * - uses lazy query with useEffect on variable changes
 * - set loading should be debounced 1s
 * - query variables are stored in redux
 *
 * TODO: fetch slots stuff..
 *
 */
import PrivateRouteWrapper from 'components/routes/PrivateRouteWrapper'
import React, { useCallback, useRef, useState } from 'react'
import MainLayout from 'components/containers/main/Main'
import { Outlet, matchPath, useLocation, useNavigate, useParams } from 'react-router'
import { routes } from 'utils/constants/routes'
import { useLazyQuery, useQuery, useMutation } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'
import ROLES from 'utils/constants/roles'
import ProgramsAndClientLicenses from '../components/modals/ProgramsAndClientLicenses'
import {
  PRODUCTS_QUERY,
  USER_QUERY_WITH_SLOTS,
  GET_AVAILABLE_SLOTS,
  UPDATE_USER,
} from './constants/constants'

import { useOnValueChange } from 'utils/hooks/useOnValueChange'
import { debounce } from 'utils/debounce'
import LoadingPage from 'components/LoadingPage'
import { setClientsSlots } from 'store/modules/clients'
import { useSnackbar } from 'notistack'
import CloseSnackbarAction from 'components/CloseSnackbarAction'
import { Button } from 'components'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { GET_INVITATIONS } from '../invitations/Invitations'

export const CLIENTS_PAGE_SIZE = 20

/**
 * isActive are highlighted tabs when the page lands on it
 * isEnabled are tabs that are not shown in navigation
 */
const getTabs = (selectedClient, currentPathname) => [
  {
    pageTitle: 'Clients',
    text: 'Clients',
    url: '/clients',
    isActive: (pathname) => matchPath({ path: '/clients' }, pathname),
    isEnabled: () => ['/clients', '/clients/pending-invitations'].includes(currentPathname),
  },
  {
    pageTitle: 'Clients',
    text: 'Pending Invitations',
    url: '/clients/pending-invitations',
    isActive: (pathname) => matchPath({ path: '/clients/pending-invitations' }, pathname),
    isEnabled: () => ['/clients', '/clients/pending-invitations'].includes(currentPathname),
  },
  {
    pageTitle: (
      <>
        Clients |{' '}
        <span className="text-link font-semibold">{`${selectedClient?.firstName} ${selectedClient?.lastName}`}</span>
      </>
    ),
    text: 'Client Details',
    url: `/clients/${selectedClient?.id}`,
    isActive: (pathname) => matchPath({ path: `/clients/${selectedClient?.id}` }, pathname),
    isEnabled: () => currentPathname.includes(selectedClient?.id),
  },
  {
    pageTitle: (
      <>
        Clients |{' '}
        <span className="text-link font-semibold">{`${selectedClient?.firstName} ${selectedClient?.lastName}`}</span>
      </>
    ),
    text: 'Client Dashboard',
    url: `/clients/${selectedClient?.id}/dashboard`,
    isActive: (pathname) =>
      matchPath({ path: `/clients/${selectedClient?.id}/dashboard` }, pathname),
    isEnabled: () => currentPathname.includes(selectedClient?.id),
  },
  {
    pageTitle: (
      <>
        Clients |{' '}
        <span className="text-link font-semibold">{`${selectedClient?.firstName} ${selectedClient?.lastName}`}</span>
      </>
    ),
    text: 'Manage Delivery',
    url: `/clients/${selectedClient?.id}/manage-delivery`,
    isActive: (pathname) =>
      matchPath({ path: `/clients/${selectedClient?.id}/manage-delivery` }, pathname),
    isEnabled: () => currentPathname.includes(selectedClient?.id),
  },
  {
    pageTitle: 'Clients | Add New Client',
    text: 'Add New Client',
    url: '/clients/create-client',
    isActive: (pathname) => matchPath({ path: '/clients/create-client' }, pathname),
    isEnabled: () => false,
  },
]

const initialQuery = {
  anyRoles: [ROLES.CLIENT],
  any: '',
  isArchived: false,
  categories: [],
  includeNoSlotClients: true,
}
export default function ClientsLayoutWrapper2() {
  // hooks
  const ref = useRef(null)
  const { enqueueSnackbar } = useSnackbar()
  const { clientId } = useParams()
  const { pathname: currentPathname } = useLocation()
  const navigate = useNavigate()

  // redux variables
  const productPreferences = useSelector((state) => get(state, 'auth.user.productPreferences', {}))
  const hasCompletedFocusCertification = !!productPreferences.focusCertification?.completedAt
  const hasCompletedSspCertification = !!productPreferences.sspCertification?.completedAt
  const hasCompletedRrpCertification = !!productPreferences.rrpCertification?.completedAt
  const { showNewSubscriptionPlan, showNewClientsPage } = useSelector((state) => state.ff)

  // state variables
  const [currentOffset, setCurrentOffset] = useState(0)
  const [loading, setLoading] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState('loading...')
  const [allDataFetched, setAllDataFetched] = useState(false)
  const [openLicensePrompt, setOpenLicensePrompt] = useState(false) // we need a temporary copy of all clients to prevent unnecessary loading UI
  const [openCreateClientPrompt, setOpenCreateClientPrompt] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [data, setData] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [chipState, setChipState] = useState({})
  const [filterState, setFilterState] = useState({
    anyRoles: [ROLES.CLIENT],
    includeNoSlotClients: true,
  })
  const [sort, setSort] = useState([])
  const [refetchCounter, setRefetchCounter] = useState(0) // we will watch refetch counter ()
  const [selectedClient, setSelectedClient] = useState({})

  // GQL
  const { data: products } = useQuery(PRODUCTS_QUERY)

  /**
   * Using network only because we have encountered caching error during really quick refreshes
   * - we cannot dynamically change queries because it will lead to caching errors
   */
  const [loadUsersWithSlots] = useLazyQuery(USER_QUERY_WITH_SLOTS, {
    fetchPolicy: 'no-cache',
  })

  const [loadSlots] = useLazyQuery(GET_AVAILABLE_SLOTS, {
    fetchPolicy: 'no-cache',
    skip: !showNewSubscriptionPlan,
  })

  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: (clientData, clientOptions) => {
      const variables = get(clientOptions, 'variables.user', {})
      const newClientsData = data.map((client) =>
        client.id !== variables.id
          ? client
          : {
              ...client,
              ...variables,
            }
      )
      setData(newClientsData)
      setSelectedClient({ ...selectedClient, ...variables })
      enqueueSnackbar('Update successful', {
        variant: 'success',
        action: CloseSnackbarAction,
      })
    },
    onError: (error) => {
      console.error(error)
      enqueueSnackbar('Server error, try again', {
        variant: 'error',
        action: CloseSnackbarAction,
      })
    },
  })

  // this is used after we update the seats of a client `ManageSeats.js`
  // we won't use set query variables here because we don't want the table to shrink to one variable..
  /**
   * 1. load users
   * 2. load slots for each user
   * 3. load total users
   */
  const onSort = ({ sort: _sort }) => {
    setSort([..._sort])
  }

  // isInvitationMode variable will be overwritten by filters. Filter is hidden for Old clients page
  const defaultInvitationModeValue = showNewClientsPage ? [true, false] : [false]

  useOnValueChange(
    JSON.stringify({
      rowsPerPage,
      page,
      filterState,
      sort,
      refetchCounter,
      clientId,
      currentPathname,
      defaultInvitationModeValue,
    }),
    async () => {
      const { data } = await loadUsersWithSlots({
        variables: {
          filter: {
            isInvitationMode: defaultInvitationModeValue,
            ...filterState,
          },
          includeCount: true,
          limit: rowsPerPage,
          offset: rowsPerPage * page,
          sort,
        },
      })

      const users = get(data, 'getUsers', [])
      const totalCount = get(data, 'getUsersCount', 0)
      setData(users)
      setTotalCount(totalCount)

      // split off the data for selected client
      const { data: clientsData } = await loadUsersWithSlots({
        variables: {
          filter: { ids: [parseInt(clientId)] },
        },
      })

      if (clientId) {
        const user = get(clientsData, 'getUsers[0]', null)
        setSelectedClient(user)
      }
    }
  )
  // every time we refetch, also refetch available slots..
  const [slotsInfo, setSlotsInfo] = useState({ ssp: [], rrp: [], focus: [] })
  const dispatch = useDispatch()

  const updateSlots = async () => {
    const { data } = await loadSlots()
    const slots = get(data, 'getSlots', [])
    const sspSlots = slots.filter((slot) => slot.category === 'ssp')
    const rrpSlots = slots.filter((slot) => slot.category === 'rrp')
    const focusSlots = slots.filter((slot) => slot.category === 'focus')
    const availableSspSlots = slots.filter(
      ({ category, status }) => category === 'ssp' && status === 'available'
    )
    const availableRrpSlots = slots.filter(
      ({ category, status }) => category === 'rrp' && status === 'available'
    )
    const availableFocusSlots = slots.filter(
      ({ category, status }) => category === 'focus' && status === 'available'
    )

    // store slots in REdux for header and local state
    dispatch(
      setClientsSlots({
        availableSspSlots: availableSspSlots.length,
        availableRrpSlots: availableRrpSlots.length,
        availableFocusSlots: availableFocusSlots.length,
      })
    )
    setSlotsInfo({
      ssp: sspSlots,
      rrp: rrpSlots,
      focus: focusSlots,
      availableFocusSlots,
      availableSspSlots,
      availableRrpSlots,
    })
  }

  const refetchClientsTableAndSlots = async () => {
    await updateSlots()

    const { data: clientsData } = await loadUsersWithSlots({
      variables: {
        filter: {
          isInvitationMode: defaultInvitationModeValue,
          ...filterState,
        },
        includeCount: true,
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        sort,
      },
    })

    const users = get(clientsData, 'getUsers', [])
    const totalCount = get(clientsData, 'getUsersCount', 0)
    setData(users)
    setTotalCount(totalCount)
  }

  useOnValueChange(JSON.stringify({ refetchCounter }), async () => {
    refetchClientsTableAndSlots()
  })

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleSearch = (value) => {
    setFilterState({ ...filterState, any: value })
  }
  const debouncedSearch = useCallback(debounce(handleSearch, 1000), [handleSearch])

  const backToClientsButton = !['/clients', '/clients/pending-invitations'].includes(
    currentPathname
  ) && (
    <Button
      variant="outlined"
      startIcon={<ArrowBackIosIcon />}
      onClick={() => navigate('/clients')}
      size="small"
      data-test="exit-to-clients-button"
      sx={{ px: 3, mb: 3 }}
    >
      Back to Clients
    </Button>
  )

  const tabs = getTabs(selectedClient, currentPathname).filter(({ isEnabled }) =>
    isEnabled(currentPathname)
  )
  const disabledTabs = getTabs(selectedClient, currentPathname).filter(
    ({ isEnabled }) => !isEnabled(currentPathname)
  )

  const [invitationData, setInvitationData] = useState([])
  useQuery(GET_INVITATIONS, {
    variables: {
      filter: {
        anyRoles: 'client',
        any: '',
        status: 'pending',
        emailStatus: '',
      },
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (response) => {
      setInvitationData(get(response, 'getInvitations', []))
    },
  })

  return (
    <PrivateRouteWrapper notAuthorized="hasProducts" roles={routes.clients.roles}>
      <LoadingPage text={loadingMessage} loading={loading}>
        <MainLayout
          title="Clients"
          tabs={tabs}
          disabledTabs={disabledTabs}
          subheaderContent={backToClientsButton}
        >
          <Outlet
            context={{
              data,
              setData,
              allDataFetched,
              currentOffset,
              fetch,
              initialQuery,
              loading,
              loadingMessage,
              openCreateClientPrompt,
              openLicensePrompt,
              products: get(products, 'getProducts', []),
              queryVars: {
                sort: [],
                filter: initialQuery,
                limit: CLIENTS_PAGE_SIZE,
                offset: 0,
              },
              ref,
              setAllDataFetched,
              setCurrentOffset,
              setLoading,
              setLoadingMessage,
              setOpenCreateClientPrompt,
              setOpenLicensePrompt,

              // pagination
              totalCount,
              page,
              rowsPerPage,
              handleChangePage,
              handleChangeRowsPerPage,

              // filters
              filterState,
              setFilterState,
              chipState,
              setChipState,
              activeIds: [],
              debouncedSearch,
              sort,
              onSort,
              setRefetchCounter,

              // slots
              refetchOne: () => {}, // we need this for legacy functions
              refetchSlots: () => {},
              slotsInfo,
              hasCompletedFocusCertification,
              hasCompletedSspCertification,
              hasCompletedRrpCertification,

              // for decoupling client creation
              selectedClient,
              setSelectedClient,
              updateUser,

              refetchClientsTableAndSlots,
              updateSlots,

              invitationData,
            }}
          />
          {showNewSubscriptionPlan && <ProgramsAndClientLicenses />}
        </MainLayout>
      </LoadingPage>
    </PrivateRouteWrapper>
  )
}
