import React from 'react'
import { useNavigate } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'

import get from 'lodash/get'
import { format } from 'date-fns'
import {
  Card,
  Grid,
  Typography,
  Box,
  UnyteTable,
  Button,
  LinearProgress,
  Link,
  Stack,
} from 'components'
import makeStyles from '@mui/styles/makeStyles'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import {
  setUser,
  setAssessmentName,
  setAssessmentFilterProductEventId,
  setFilterUserId,
  resetFilter,
} from 'store/modules/assessments'
import { ASSESSMENT_STATUS } from 'utils/constants/assessmentStatus'

const useStyles = makeStyles({
  card: {
    width: '100%',
    backgroundColor: '#edf2f7',
  },
})

const {
  SENT_TO_CLIENT,
  IN_PROGRESS_BY_CLIENT,
  IN_PROGRESS,
  COMPLETED,
  isInProgressByClient,
  isCompleted,
} = ASSESSMENT_STATUS

const STATUS_COLOR = {
  [SENT_TO_CLIENT]: 'rgba(255,172,28,1)',
  [IN_PROGRESS]: 'rgba(255,130,188,255)',
  [IN_PROGRESS_BY_CLIENT]: 'rgba(255,130,188,255)',
  [COMPLETED]: 'rgba(43,145,150,255)',
}

function RecentAssessmentsDetailsCard({ gridProps }) {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const clients = useSelector((state) => get(state, 'assessments.userData', []))
  const assessments = useSelector((state) => get(state, 'assessments.productAssessments', []))
  const assessmentsOfLastThirtyDays = useSelector((state) => get(state, 'assessments.sessions', []))

  const assessmentsCompleted = assessmentsOfLastThirtyDays.filter(({ data: { status } }) =>
    isCompleted(status)
  ).length

  // don't divide by zero. If assessmentsOfLastThirtyDays is 0, then return 0.
  const linearValue =
    assessmentsOfLastThirtyDays.length > 0
      ? (assessmentsCompleted / assessmentsOfLastThirtyDays.length) * 100
      : 0

  const assessmentSelected = async (data) => {
    const user = clients.find(({ id }) => id === data?.user?.id)
    const assessment = assessments.find(({ id }) => id === data?.productEventId)

    if (user && assessment) {
      // get variables
      const longAssessmentName = assessment?.metadata?.variant
        ? `${assessment.metadata.assessmentType} - ${assessment.metadata.variant}`
        : assessment.metadata.assessmentType

      const productEventId = get(data, 'productEventId', undefined)
      const sessionId = get(data, 'id', undefined)
      const productEvent = assessments.find(({ id }) => productEventId === id)
      const isIntakeForm = get(productEvent, 'metadata.isIntakeForm', false)

      // dispatch the user in order to set the filters on Assessments page.
      await dispatch(
        setUser({
          ...user,
          userId: user.id,
        })
      )
      // dispatch the Assessment in order to set the filters on Assessments page.

      if (productEventId) {
        await dispatch(setAssessmentFilterProductEventId(productEventId))
        await dispatch(setAssessmentName(longAssessmentName))
      }
      await dispatch(setFilterUserId(user.id))

      /**
       * navigate to the reports tab if completed.
       * in progress? go to assessment and set user to filter
       * intake form? go to response
       */
      if (isCompleted(data.data.status) && isIntakeForm) {
        navigate(`/assessments/update/${user.id}/${sessionId}`)
      } else if (isCompleted(data.data.status)) {
        navigate(`/assessments/reports/${user.id}`)
      } else {
        // remove all previous filter and navigate
        await dispatch(resetFilter())
        await navigate(`/assessments/${user.id}`, { state: { hideGettingStarted: true } })
      }
    }
  }

  const formatDate = (date) => {
    if (new Date(date).getFullYear() === new Date().getFullYear()) {
      // leave out the year if it is current year.
      return format(new Date(date), "MMM d 'at' h:mm a")
    }
    return format(new Date(date), "MMM d, yyyy 'at' h:mm a")
  }

  // data here is the session
  const getMetadataShortTitle = (data) => {
    const productEventId = get(data, 'productEventId', null)
    return assessments.find(({ id }) => id === productEventId)?.metadata?.shortTitle
  }

  return (
    <Grid
      item
      {...gridProps}
      key="recent-completed-assessment-detailed-card"
      xs={12}
      sm={8}
      md={8}
      lg={4}
    >
      <Card data-test="dashboard-card-recent-completed-assessment" elevation={0}>
        <Grid container className={`${classes.card} h-full`} alignItems="center">
          <Box className={`${classes.card}`}>
            <Typography className="pl-4 pt-4" variant="h5">
              Recent Assessment Activity
            </Typography>
            <Typography className="pl-4" variant="body1">
              Assessments completed: {assessmentsCompleted} / {assessmentsOfLastThirtyDays.length}{' '}
              <span style={{ fontSize: 12 }}>(last 30 days)</span>{' '}
            </Typography>

            <LinearProgress
              className="m-4"
              variant="determinate"
              color="primary"
              value={linearValue}
              style={{ height: 10, borderRadius: 5 }}
            />

            <UnyteTable
              data={[...assessmentsOfLastThirtyDays]
                .sort((a, b) => {
                  // Convert updatedAt to Date objects
                  let dateA = new Date(a.updatedAt)
                  let dateB = new Date(b.updatedAt)

                  // Sort in descending order
                  return dateB - dateA
                })
                .slice(0, 3)}
              hideTableHead
              columns={[
                {
                  width: '50%',
                  body: ({ data }) => (
                    <Typography style={{ fontSize: 15 }}> {data?.user?.fullName} </Typography>
                  ),
                },
                {
                  width: '45%',
                  body: ({ data }) => (
                    <>
                      <Typography variant="caption">{formatDate(data.updatedAt)}</Typography>
                      <Typography variant="subtitle2">
                        {getMetadataShortTitle(data)}{' '}
                        <span style={{ color: get(STATUS_COLOR, data.data.status, 'gray') }}>
                          {isInProgressByClient(data.data.status) ? IN_PROGRESS : data.data.status}
                        </span>
                      </Typography>
                    </>
                  ),
                },
                {
                  width: '5%',
                  body: ({ data }) => (
                    <Button
                      variant="outlined"
                      onClick={() => assessmentSelected(data)}
                      style={{
                        maxWidth: '25px',
                        maxHeight: '40px',
                        minWidth: '25px',
                        minHeight: '40px',
                      }}
                    >
                      <KeyboardArrowRightIcon />
                    </Button>
                  ),
                },
              ]}
            />
            <Stack className="pt-2 pb-2" direction="row" justifyContent="center">
              <Typography
                variant="caption"
                component={Link}
                to="/assessments"
                sx={{ textDecoration: 'none' }}
              >
                See All Assessment Activities
              </Typography>
            </Stack>
          </Box>
        </Grid>
      </Card>
    </Grid>
  )
}

export default RecentAssessmentsDetailsCard
