import { useQuery, gql } from 'utils/apollo'
import get from 'lodash/get'

const GET_WELCOME_CALL = gql`
  query UseGetShowWelcomeCall {
    getShowWelcomeCall
  }
`

export default () => {
  const { data, loading } = useQuery(GET_WELCOME_CALL, {
    fetchPolicy: 'cache-and-network',
  })

  const showUserWelcomeCall = get(data, 'getShowWelcomeCall', false)
  return {
    showUserWelcomeCall,
    loading,
  }
}
