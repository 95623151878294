import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useOutletContext } from 'react-router'
import get from 'lodash/get'
import ROLES from 'utils/constants/roles'

export default function LicensePromptOnClientCreation() {
  const navigate = useNavigate()
  const { openLicensePrompt, setOpenLicensePrompt, setOpenCreateClientPrompt } = useOutletContext()
  const { roles: userRoles } = useSelector((state) => get(state, 'auth.user', {}))

  const isBillingProvider = userRoles.includes(ROLES.BILLING)

  const handleCancel = () => {
    setOpenLicensePrompt(false)
  }

  const handlePurchaseMoreLicenses = () => {
    navigate('/store')
  }

  const handleCreateClient = () => {
    setOpenCreateClientPrompt(true)
  }

  return (
    <Dialog open={openLicensePrompt} maxWidth="sm">
      <DialogTitle>No Available Client Licences</DialogTitle>
      <DialogContent>
        <Typography variant="body2" gutterBottom>
          <span className="font-medium">Please note:</span> you have used all your available client
          licenses.
        </Typography>
        <Typography variant="body2" gutterBottom my={1}>
          You can still create a new client, however, you will be unable to assign them a program
          until you have an available license.
        </Typography>
        {!isBillingProvider && (
          <Typography variant="body2" gutterBottom my={1}>
            You can either unassign any unused licenses, or contact your administrator to purchase
            additional licenses.
          </Typography>
        )}
        {isBillingProvider && (
          <Typography variant="body2" gutterBottom my={1}>
            You can either purchase more licenses (now or later), or unassign any unused assigned
            licenses (if any).
          </Typography>
        )}
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', m: 1 }}>
        <Button color="secondary" sx={{ width: '200px' }} onClick={handleCancel}>
          Cancel
        </Button>
        <Button sx={{ width: '200px' }} onClick={handlePurchaseMoreLicenses}>
          Purchase More Licenses
        </Button>
        <Button sx={{ width: '200px' }} onClick={handleCreateClient}>
          Create Client
        </Button>
      </DialogActions>
    </Dialog>
  )
}
