import get from 'lodash/get'

/**
 * Examples:
 * - 1 Main ST APT 52, Denver, CO 80206, United States of America
 * - Adler str. 1, BW 12345, Germany,
 * - Seattle, WA, United States of America
 * @param {object} address address1, address2, city, state, isoState, country, isoCountry
 */
export default function formatAddress({
  address1,
  address2,
  city,
  state,
  isoState = '',
  postalCode,
  country,
  phoneNumber,
}) {
  const matchedPhoneNumber = phoneNumber?.match(/\d+/g)?.join('')
  const address = [
    [address1, address2].filter(Boolean).join(' '),
    city,
    [/\D{2,}/.test(isoState) ? isoState : state, postalCode] // States that have longer than two non-digit code use ISO standard
      .filter(Boolean)
      .join(' '),
    [country, matchedPhoneNumber && `(+${matchedPhoneNumber})`].filter(Boolean).join(' '),
  ]
    .filter(Boolean)
    .join(', ')

  return address
}

export function formatRestrictionAddress(restriction) {
  const countries = get(restriction, 'countries', []).map(({ name }) => name)
  return formatAddress({
    country: countries.join(', '),
    state: get(restriction, 'states[0].name', ''),
    isoState: get(restriction, 'states[0].isoCode', ''),
  })
}

/**
 * An address is empty when its mandatory fields are empty
 */
export function isAddressEmpty({ address1, city, state, isoState, postalCode, country }) {
  return !(!!address1 && !!city && !!state && !!isoState && !!postalCode && !!country)
}
