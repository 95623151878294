import React, { useEffect } from 'react'
import {
  Card,
  Grid,
  Divider,
  CardContent,
  CardHeader,
  Link,
  Typography,
  Container,
} from 'components'
import makeStyles from '@mui/styles/makeStyles'
import { useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'
import MainLayout from 'components/containers/main/Main'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import EMAILS from 'utils/constants/emails'
import LINKS from 'utils/constants/links'
import {
  ALL_CERTIFICATION_SKUS,
  FOCUS_SYSTEM_SKUS,
  ALL_SSP_SUBSCRIPTION_SKUS,
} from '../../utils/constants/prices'
import { resetStore } from '../../store/modules/new-purchase'
import useGTM from 'utils/hooks/useGTM'

// have a specific message for all product categories
const MESSAGE = {
  certification: (
    <Typography variant="body1" gutterBottom className="pt-2">
      Click on{' '}
      <Link to="/academy" className="text-link font-medium">
        Academy
      </Link>{' '}
      in the left menu to access your training.
    </Typography>
  ),
  sspSubscription: (
    <>
      <Typography variant="body1" gutterBottom className="pt-2">
        Your SSP subscription is now active and you are ready to begin using the SSP!
      </Typography>
      <Typography variant="body1" gutterBottom className="pt-2">
        We are excited for you to get started delivering this powerful tool with your clients.
      </Typography>
      <Typography variant="body1" gutterBottom className="pt-2">
        Your next step is to return to your MyUnyte{' '}
        <Link to="/" className="text-link font-medium">
          Dashboard
        </Link>{' '}
        and watch the intro video.
      </Typography>
    </>
  ),
  physicalProduct: (
    <>
      <Typography variant="body1" gutterBottom className="pt-2">
        We will get to work on fulfilling your order as quickly as we can.
      </Typography>
      <Typography variant="body1" gutterBottom className="pt-2">
        Orders are typically picked and packed within 48 hours (2 business days) and submitted to
        your shipping courier. Once available, we will send you an email with order tracking
        information.
      </Typography>
    </>
  ),
}

const useStyles = makeStyles({
  cardHeader: {
    fontWeight: '500',
    fontSize: '1.5rem',
    lineHeight: 1.334,
    letterSpacing: '0em',
  },
})

export default function Success() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { pageView } = useGTM()
  const firstName = useSelector((state) => get(state, 'auth.user.firstName', ''))

  // ils products are not in onboardingProducts
  const onboardingProducts = useSelector((state) =>
    get(state, 'newPurchase.data.onboardingProducts', [])
  )
  const selectedAddOns = useSelector((state) => get(state, 'newPurchase.data.selectedAddOns', {}))
  const hasAddOns = Object.keys(selectedAddOns).some((key) => selectedAddOns[key] > 0)

  const hasCertification =
    onboardingProducts.length &&
    onboardingProducts.every((key) => ALL_CERTIFICATION_SKUS.includes(key)) &&
    'certification'
  const hasSspSubscription =
    onboardingProducts.some((key) => ALL_SSP_SUBSCRIPTION_SKUS.includes(key)) && 'sspSubscription'
  const type = hasCertification || hasSspSubscription

  const hasPhysicalProduct =
    (onboardingProducts.some((key) => FOCUS_SYSTEM_SKUS.includes(key)) || hasAddOns) &&
    'physicalProduct'

  // onLoad - add pageview to GTM
  // when we leave this compenent, clear the store
  useEffect(() => {
    pageView({
      pageUrl: window.document.location.href,
      pageTitle: 'Internal Purchase - Purchase complete',
    })
    return () => dispatch(resetStore())
    // eslint-disable-next-line
  }, [])

  return (
    <MainLayout title="Success!">
      <Container maxWidth="lg">
        <Grid container justifyContent="center">
          <Grid className="p-5" item xs={12} md={7}>
            <Card>
              <CardHeader
                title="Purchase Complete"
                avatar={<CheckCircleIcon color="primary" fontSize="large" />}
                classes={{ title: classes.cardHeader }}
              />
              <Divider />
              <CardContent>
                <Typography variant="body1" gutterBottom className="pt-2">
                  {firstName.trim()}, thank you for your purchase!
                </Typography>
                {MESSAGE[type]}
                {MESSAGE[hasPhysicalProduct]}
                <Typography variant="body1" gutterBottom className="pt-2">
                  We are here to help. Please visit{' '}
                  <a
                    href={LINKS.support.homepageUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-link font-medium"
                  >
                    support.unyte.com
                  </a>{' '}
                  for answers to many common questions or contact us at{' '}
                  <a href={`mailto:${EMAILS.supportEmail}`} className="text-link font-medium">
                    {EMAILS.supportEmail}
                  </a>
                  .
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </MainLayout>
  )
}
