/***
 * This page is refactored from the component success..
 */

import React, { useEffect } from 'react'
import { Divider, List, ListItem, CardHeader, CardContent, Typography, Link } from 'components'
import makeStyles from '@mui/styles/makeStyles'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Layout from './components/Layout'
import EMAILS from 'utils/constants/emails'
import LINKS from 'utils/constants/links'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import { useNavigate } from 'react-router-dom'
import useGTM from 'utils/hooks/useGTM'

const useStyles = makeStyles({
  // default CardHeader css changes when you add an avatar
  cardHeader: {
    fontWeight: '500',
    fontSize: '1.5rem',
    lineHeight: 1.334,
    letterSpacing: '0em',
  },
})

export default () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { pageView } = useGTM()
  const { adminFirstName = '', adminEmail = '', onboardingProducts = [] } = useSelector((state) =>
    get(state, 'newPurchase.data', {})
  )

  // if there are no purchase data, return to home page
  if (!onboardingProducts.length) {
    navigate('/sign-in')
  }

  useEffect(() => {
    pageView({
      pageUrl: window.document.location.href,
      pageTitle: 'New Purchase - Purchase complete',
    })
    // eslint-disable-next-line
  }, [])

  return (
    <div
      style={{
        minHeight: '100vh',
        backgroundColor: '#eeeeee',
      }}
      data-test="purchase-completed"
    >
      <Layout hideCartEdit hideProgress isPurchaseComplete>
        <CardHeader
          title="Purchase Complete"
          classes={{ title: classes.cardHeader }}
          avatar={<CheckCircleIcon color="primary" fontSize="large" />}
        />
        <CardContent>
          <Typography variant="body1" gutterBottom className="pt-2">
            Thank you {adminFirstName.trim()}!
          </Typography>
          <>
            <Typography variant="body1" gutterBottom className="pt-2">
              We're excited that you've decided to join the Unyte Health provider community and look
              forward to working with you!
            </Typography>
            <Typography variant="body1" gutterBottom className="pt-2">
              The next important step is to create your MyUnyte account. An email with directions on
              how to create your MyUnyte account was sent to <b>{adminEmail}</b>. Please follow the
              directions in the email.
            </Typography>
          </>
          <Typography variant="h5" gutterBottom className="pt-4">
            Tips
          </Typography>
          <Divider />
          <div>
            <List dense={true}>
              <ListItem>
                <Typography variant="body1">
                  1. Check your spam folder if you can't find the email.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  2. Search your email inbox for an email from "{EMAILS.supportEmail}".
                </Typography>
              </ListItem>
            </List>
          </div>
          <Typography variant="h5" gutterBottom className="pt-4">
            We're Here To Help
          </Typography>
          <Divider />
          <Typography variant="body1" gutterBottom className="pt-2">
            If you have any questions or need support, we are here to help.
          </Typography>
          <Typography variant="body1" gutterBottom className="pt-2">
            For answers to common questions, please visit{' '}
            <Link target="_blank" to={LINKS.support.homepageUrl} className="text-link">
              {LINKS.support.domainName}
            </Link>
          </Typography>
          <Typography variant="body1" gutterBottom className="pt-2">
            We are also available by email at{' '}
            <a href={`mailto:${EMAILS.supportEmail}`} className="text-link">
              {EMAILS.supportEmail}
            </a>
          </Typography>
        </CardContent>
      </Layout>
    </div>
  )
}
