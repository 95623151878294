import React from 'react'
import AcademyLogo from './unyte-logo-academy.svg'
import { Avatar } from '@mui/material'

export default function AcademyLogoAvatar({ height = '100%' }) {
  return (
    <Avatar
      variant="square"
      src={AcademyLogo}
      alt="academy-icon"
      sx={{
        width: '100%',
        backgroundColor: 'transparent',
        objectFit: 'cover',
        height,
      }}
    />
  )
}
