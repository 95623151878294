import React from 'react'
import get from 'lodash/get'
import { Grid } from 'components'
import moment from 'utils/moment'

import formats from 'utils/constants/formats'
import { formatSeconds } from 'utils'

import styles from './Sessions.module.scss'

const pauses = (n) => {
  if (n === 0) return `(no pauses)`
  else if (n === 1) return `(${n} pause)`
  else return `(${n} pauses)`
}

export default function ListCard({ data, onClick }) {
  const totalPausedTime = formatSeconds(data.totalTime - data.totalInteractionTime)
  const totalTime = formatSeconds(data.totalTime)
  const totalInteractionTime = formatSeconds(data.totalInteractionTime) || '0s'

  return (
    <Grid container alignItems="center" className={styles.listItem} onClick={onClick}>
      <Grid className="py-3 mx-2" item xs={1}>
        <img alt="" style={{ maxWidth: '100%' }} src={data.image} />
      </Grid>
      <Grid item xs={6}>
        <b>{data.productName || 'Program is unavailable'}</b>
        <br />
        {data.eventName || 'Program is unavailable'}
        <br />
        {moment(data.startedAt).format(formats.dateAndTime)}
      </Grid>
      <Grid item xs={4}>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-end"
          style={{
            height: '100%',
            position: 'relative',
          }}
        >
          <Grid>
            <div
              style={{
                height: 10,
                width: 10,
                borderRadius: '50%',
                backgroundColor: data.color,
                position: 'absolute',
                left: 0,
                top: '50%',
                transform: 'translateY(-50%)',
              }}
            />
          </Grid>
          <Grid>
            <div style={{ textAlign: 'right' }}>
              {data.type === 'interactive_meditation' && get(data, 'data.avgCoherence') && (
                <div>Completed</div>
              )}
              <div style={{ fontWeight: 'bold' }}>Listening: {totalInteractionTime}</div>
              <div>{!!totalTime && `Total: ${totalTime}`}</div>
              <div>{!!totalPausedTime && `Paused: ${totalPausedTime}`}</div>
              {data.type === 'ssp' && <div>{pauses(get(data, 'data.pausesCount', 0))}</div>}
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
