import React from 'react'
import { Typography } from '@mui/material'

import {
  RRP_BANNER_STATE_2_ACCEPTED,
  RRP_BANNER_STATE_3_ACTIVE,
} from '../../../utils/constants/rrp-banner-cards'

export const sspCardConfigs = [
  {
    title: 'Annual Upfront Plan',
    costPerMonth: 125,
    description: <Typography>Prepay $1,499 today, billed annually, auto-renewal</Typography>,
    subDescription: ({ hasActiveFocusSubscription }) => (
      <Typography
        sx={{
          span: { color: '#ce0093' },
        }}
        variant="body2"
      >
        {hasActiveFocusSubscription && (
          <>
            Save an <span>additional $500</span> if you have an active ILS yearly subscription!
          </>
        )}
      </Typography>
    ),
    link: '/purchase/products',
    skus: ['sspYearToYear_Yearly'],
    isActive: ({
      hasOrgSspCertificationCompleted,
      hasActiveSspSubscription,
      hasSspCanceledSubscription,
    }) =>
      (hasOrgSspCertificationCompleted && !hasActiveSspSubscription) || hasSspCanceledSubscription,
    testName: 'ssp-upfront-sub-card',
  },
  {
    title: 'Annual Monthly Plan',
    costPerMonth: 139,
    description: <Typography>12-month subscription, billed monthly, auto-renewal</Typography>,
    subDescription: ({ hasActiveFocusSubscription }) => (
      <Typography
        sx={{
          span: { color: '#ce0093' },
        }}
        variant="body2"
      >
        {hasActiveFocusSubscription && (
          <>
            Save an <span>additional $500</span> if you have an active ILS yearly subscription!
          </>
        )}
      </Typography>
    ),
    link: '/purchase/products',
    skus: ['sspMonthToMonth_Yearly'],
    isActive: ({
      hasOrgSspCertificationCompleted,
      hasActiveSspSubscription,
      hasSspCanceledSubscription,
    }) =>
      (hasOrgSspCertificationCompleted && !hasActiveSspSubscription) || hasSspCanceledSubscription,
    testName: 'ssp-monthly-sub-card',
  },
  {
    title: '3-Month Plan',
    costPerMonth: 169,
    description: <Typography>3-month subscription, billed monthly, auto-renewal</Typography>,
    subDescription: () => '', // fill out bottom of card
    link: '/purchase/products',
    skus: ['sspMonthToMonth_3Monthly'],
    isActive: ({
      hasOrgSspCertificationCompleted,
      hasActiveSspSubscription,
      hasSspCanceledSubscription,
    }) =>
      (hasOrgSspCertificationCompleted && !hasActiveSspSubscription) || hasSspCanceledSubscription,
    testName: 'ssp-3month-sub-card',
  },
  {
    title: 'Annual Upfront Plan',
    costPerMonth: 125,
    description: <Typography>Prepay $1,499 today, billed annually, auto-renewal</Typography>,
    trainingMessage: (
      <Typography
        variant="body1"
        sx={{
          fontWeight: 700,
          span: { color: '#ce0093' },
        }}
      >
        +$299 <span>(save $100)</span> for Foundational SSP Training
      </Typography>
    ),
    subDescription: ({ hasActiveFocusSubscription }) => (
      <Typography
        sx={{
          span: { color: '#ce0093' },
        }}
        variant="body2"
      >
        {hasActiveFocusSubscription && (
          <>
            Save an <span>additional $500</span> if you have an active ILS yearly subscription!
          </>
        )}
      </Typography>
    ),
    link: '/purchase/products',
    skus: ['sspCertification', 'sspYearToYear_Yearly'],
    isActive: ({ hasSspCertification, hasActiveSspSubscription }) =>
      !hasSspCertification && !hasActiveSspSubscription,
    testName: 'ssp-upfront-bundle-card',
  },
  {
    title: 'Annual Monthly Plan',
    costPerMonth: 139,
    description: <Typography>12-month subscription, billed monthly, auto-renewal</Typography>,
    trainingMessage: (
      <Typography
        variant="body1"
        sx={{
          fontWeight: 700,
          span: { color: '#ce0093' },
        }}
      >
        +$299 <span>(save $100)</span> for Foundational SSP Training
      </Typography>
    ),
    subDescription: ({ hasActiveFocusSubscription }) => (
      <Typography
        sx={{
          span: { color: '#ce0093' },
        }}
        variant="body2"
      >
        {hasActiveFocusSubscription && (
          <>
            Save an <span>additional $500</span> if you have an active ILS yearly subscription!
          </>
        )}
      </Typography>
    ),
    link: '/purchase/products',
    skus: ['sspCertification', 'sspMonthToMonth_Yearly_Bundle'],
    isActive: ({ hasSspCertification, hasActiveSspSubscription }) =>
      !hasSspCertification && !hasActiveSspSubscription,
    testName: 'ssp-monthly-bundle-card',
  },
  {
    title: '3-Month Plan',
    costPerMonth: 169,
    description: <Typography>3-month subscription, billed monthly, auto-renewal</Typography>,
    trainingMessage: (
      <Typography variant="body1" sx={{ fontWeight: 700 }}>
        +$399 for Foundational SSP Training
      </Typography>
    ),
    subDescription: () => '', // fill out bottom of card
    link: '/purchase/products',
    skus: ['sspCertification', 'sspMonthToMonth_3Monthly_Bundle'],
    isActive: ({ hasSspCertification, hasActiveSspSubscription }) =>
      !hasSspCertification && !hasActiveSspSubscription,
    testName: 'ssp-3month-bundle-card',
  },
]

export function rrpCardConfigs({
  hasActiveFocusSubscription,
  hasActiveRrpSubscription,
  hasActiveSspSubscription,
  hasAllCanceledRrpSubscription,
  hasOrgRrpCertificationEnrolled,
  hasSsp3MonthlySubscription,
  rrpFreeSampleState,
}) {
  return [
    {
      title: 'Annual Upfront Plan',
      costPerMonth: 125,
      multiProductDiscountAmt: hasSsp3MonthlySubscription ? null : 66,
      description:
        !hasSsp3MonthlySubscription && (hasActiveFocusSubscription || hasActiveSspSubscription) ? (
          <Typography>
            Prepay <s>$1,499</s> $700 today, billed annually, auto-renewal
          </Typography>
        ) : (
          <Typography>Prepay $1,499 today, billed annually, auto-renewal</Typography>
        ),
      subDescription: (
        <>
          <Typography
            sx={{
              span: { color: '#ce0093' },
            }}
            variant="body2"
          >
            {(hasActiveFocusSubscription ||
              (hasActiveSspSubscription && !hasSsp3MonthlySubscription)) && (
              <>
                You will save <span>$799 per year </span> with your active SSP subscription!
                <br />
                <br />
                <i>Active SSP Subscription required for discount.</i>
              </>
            )}
          </Typography>
          {[RRP_BANNER_STATE_2_ACCEPTED, RRP_BANNER_STATE_3_ACTIVE].includes(
            rrpFreeSampleState
          ) && (
            <Typography sx={{ span: { color: '#ce0093' } }} mt={3} variant="body2">
              {'Upon purchase, subscription will start when trial ends.'}
            </Typography>
          )}
        </>
      ),
      link: '/purchase/products',
      skus: ['rrpYearToYear_Yearly'],
      isActive:
        (hasOrgRrpCertificationEnrolled && !hasActiveRrpSubscription) ||
        hasAllCanceledRrpSubscription,
      testName: 'rrp-upfront-sub-card',
    },
    {
      title: 'Annual Monthly Plan',
      costPerMonth: 139,
      multiProductDiscountAmt: hasSsp3MonthlySubscription ? 89 : 79,
      description: <Typography>12-month subscription, billed monthly, auto-renewal</Typography>,
      subDescription: (
        <>
          <Typography
            sx={{
              span: { color: '#ce0093' },
            }}
            variant="body2"
          >
            {(hasActiveFocusSubscription || hasActiveSspSubscription) && (
              <>
                <>You will save </>
                <span>{` ${hasSsp3MonthlySubscription ? '$89' : '$79'} per month `}</span> with your
                active SSP subscription!
                <br />
                <br />
                <i>Active SSP Subscription required for discount.</i>
              </>
            )}
          </Typography>
          {[RRP_BANNER_STATE_2_ACCEPTED, RRP_BANNER_STATE_3_ACTIVE].includes(
            rrpFreeSampleState
          ) && (
            <Typography sx={{ span: { color: '#ce0093' } }} mt={3} variant="body2">
              {'Upon purchase, subscription will start when trial ends.'}
            </Typography>
          )}
        </>
      ),
      link: '/purchase/products',
      skus: ['rrpMonthToMonth_Yearly'],
      isActive:
        (hasOrgRrpCertificationEnrolled && !hasActiveRrpSubscription) ||
        hasAllCanceledRrpSubscription,
      testName: 'rrp-monthly-sub-card',
    },
    {
      title: '3-Month Plan',
      costPerMonth: 169,
      multiProductDiscountAmt: 89,
      description: <Typography>3-month subscription, billed monthly, auto-renewal</Typography>,
      subDescription: (
        <>
          <Typography
            sx={{
              span: { color: '#ce0093' },
            }}
            variant="body2"
          >
            {(hasActiveFocusSubscription || hasActiveSspSubscription) && (
              <>
                You will save <span>$89 per month </span> with your active SSP subscription!
                <br />
                <br />
                <i>Active SSP Subscription required for discount.</i>
              </>
            )}
          </Typography>
          {[RRP_BANNER_STATE_2_ACCEPTED, RRP_BANNER_STATE_3_ACTIVE].includes(
            rrpFreeSampleState
          ) && (
            <Typography sx={{ span: { color: '#ce0093' } }} mt={3} variant="body2">
              {'Upon purchase, subscription will start when trial ends.'}
            </Typography>
          )}
        </>
      ),
      link: '/purchase/products',
      skus: ['rrpMonthToMonth_3Monthly'],
      isActive:
        (hasOrgRrpCertificationEnrolled && !hasActiveRrpSubscription) ||
        hasAllCanceledRrpSubscription,
      testName: 'rrp-3month-sub-card',
    },
    {
      title: 'Annual Upfront Plan',
      costPerMonth: 125,
      multiProductDiscountAmt: hasSsp3MonthlySubscription ? null : 66,
      description:
        !hasSsp3MonthlySubscription && (hasActiveFocusSubscription || hasActiveSspSubscription) ? (
          <Typography>
            Prepay <s>$1,499</s> $700 today, billed annually, auto-renewal
          </Typography>
        ) : (
          <Typography>Prepay $1,499 today, billed annually, auto-renewal</Typography>
        ),
      trainingMessage: (
        <Typography
          variant="body1"
          sx={{
            fontWeight: 700,
            span: { color: '#ce0093' },
          }}
        >
          + Includes Preliminary RRP Training
        </Typography>
      ),
      subDescription: (
        <Typography
          sx={{
            span: { color: '#ce0093' },
          }}
          variant="body2"
        >
          {(hasActiveFocusSubscription ||
            (hasActiveSspSubscription && !hasSsp3MonthlySubscription)) && (
            <>
              You will save <span>$799 per year </span> with your active SSP subscription!
              <br />
              <br />
              <i>Active SSP Subscription required for discount.</i>
            </>
          )}
        </Typography>
      ),
      link: '/purchase/products',
      skus: ['rrpCertification', 'rrpYearToYear_Yearly'],
      isActive: !hasOrgRrpCertificationEnrolled && !hasActiveRrpSubscription,
      testName: 'rrp-upfront-bundle-card',
    },
    {
      title: 'Annual Monthly Plan',
      costPerMonth: 139,
      multiProductDiscountAmt: hasSsp3MonthlySubscription ? 89 : 79,
      description: <Typography>12-month subscription, billed monthly, auto-renewal</Typography>,
      trainingMessage: (
        <Typography
          variant="body1"
          sx={{
            fontWeight: 700,
            span: { color: '#ce0093' },
          }}
        >
          + Includes Preliminary RRP Training
        </Typography>
      ),
      subDescription: (
        <Typography
          sx={{
            span: { color: '#ce0093' },
          }}
          variant="body2"
        >
          {(hasActiveFocusSubscription || hasActiveSspSubscription) && (
            <>
              You will save
              <span>{` ${hasSsp3MonthlySubscription ? '89$' : '79$'} per month `}</span> with your
              active SSP subscription!
              <br />
              <br />
              <i>Active SSP Subscription required for discount.</i>
            </>
          )}
        </Typography>
      ),
      link: '/purchase/products',
      skus: ['rrpCertification', 'rrpMonthToMonth_Yearly_Bundle'],
      isActive: !hasOrgRrpCertificationEnrolled && !hasActiveRrpSubscription,
      testName: 'rrp-monthly-bundle-card',
    },
    {
      title: '3-Month Plan',
      costPerMonth: 169,
      multiProductDiscountAmt: 89,
      description: <Typography>3-month subscription, billed monthly, auto-renewal</Typography>,
      trainingMessage: (
        <Typography variant="body1" sx={{ fontWeight: 700 }}>
          + Includes Preliminary RRP Training
        </Typography>
      ),
      subDescription: (
        <Typography
          sx={{
            span: { color: '#ce0093' },
          }}
          variant="body2"
        >
          {(hasActiveFocusSubscription || hasActiveSspSubscription) && (
            <>
              You will save <span>$89 per month </span> with your active SSP subscription!
              <br />
              <br />
              <i>Active SSP Subscription required for discount.</i>
            </>
          )}
        </Typography>
      ),
      link: '/purchase/products',
      skus: ['rrpCertification', 'rrpMonthToMonth_3Monthly_Bundle'],
      isActive: !hasOrgRrpCertificationEnrolled && !hasActiveRrpSubscription,
      testName: 'rrp-3month-bundle-card',
    },
  ]
}

export const focusCardConfigs = [
  {
    title: 'Annual Upfront Plan',
    costPerMonth: 125,
    description: (
      <Typography variant="subtitle1">
        Prepay $1,499 today, billed annually, auto-renewal
      </Typography>
    ),
    subDescription: ({ hasActiveSspSubscription }) => (
      <>
        <Typography variant="subtitle2">
          Includes One Starter Pack: Hardware Kit + Integration Kit
        </Typography>
        {hasActiveSspSubscription && (
          <Typography
            sx={{
              span: { color: '#ce0093' },
            }}
            variant="body2"
          >
            <br />
            Save an <span>additional $500</span> if you have an active SSP yearly subscription!
          </Typography>
        )}
      </>
    ),
    link: '/purchase/shipping',
    skus: ['focusYearToYear_Yearly', 'FOCUS-STRT'],
    isActive: ({
      hasOrgFocusCertificationCompleted,
      hasActiveFocusSubscription,
      hasAllCanceledFocusSubscription,
    }) =>
      (hasOrgFocusCertificationCompleted && !hasActiveFocusSubscription) ||
      hasAllCanceledFocusSubscription,
    testName: 'ils-upfront-sub-card',
  },
  {
    title: 'Annual Monthly Plan',
    costPerMonth: 139,
    description: (
      <Typography variant="subtitle1">
        12-month subscription, billed monthly, auto-renewal
      </Typography>
    ),
    subDescription: ({ hasActiveSspSubscription }) => (
      <>
        <Typography variant="subtitle2">
          Includes One Starter Pack: Hardware Kit + Integration Kit
        </Typography>
        {hasActiveSspSubscription && (
          <Typography
            sx={{
              span: { color: '#ce0093' },
            }}
            variant="body2"
          >
            <br />
            Save an <span>additional $500</span> if you have an active SSP yearly subscription!
          </Typography>
        )}
      </>
    ),
    link: '/purchase/shipping',
    skus: ['focusMonthToMonth_Yearly', 'FOCUS-STRT'],
    isActive: ({
      hasOrgFocusCertificationCompleted,
      hasActiveFocusSubscription,
      hasAllCanceledFocusSubscription,
    }) =>
      (hasOrgFocusCertificationCompleted && !hasActiveFocusSubscription) ||
      hasAllCanceledFocusSubscription,
    testName: 'ils-monthly-sub-card',
  },

  {
    title: 'Annual Upfront Plan',
    costPerMonth: 125,
    description: (
      <Typography variant="subtitle1">
        Prepay $1,499 today, billed annually, auto-renewal
      </Typography>
    ),
    trainingMessage: (
      <Typography
        variant="body1"
        sx={{
          fontWeight: 700,
          span: { color: '#ce0093' },
        }}
      >
        +$149 <span>(save $50)</span> for Foundational ILS Training
      </Typography>
    ),
    subDescription: ({ hasActiveSspSubscription }) => (
      <>
        <Typography variant="subtitle2">
          Includes One Starter Pack: Hardware Kit + Integration Kit
        </Typography>
        {hasActiveSspSubscription && (
          <Typography
            sx={{
              span: { color: '#ce0093' },
            }}
            variant="body2"
          >
            <br />
            Save an <span>additional $500</span> if you have an active SSP yearly subscription!
          </Typography>
        )}
      </>
    ),
    link: '/purchase/shipping',
    skus: ['focusCertification', 'focusYearToYear_Yearly', 'FOCUS-STRT'],
    isActive: ({ hasFocusCertification, hasActiveFocusSubscription }) =>
      !hasFocusCertification && !hasActiveFocusSubscription,
    testName: 'ils-upfront-bundle-card',
  },
  {
    title: 'Annual Monthly Plan',
    costPerMonth: 139,
    description: (
      <Typography variant="subtitle1">
        12-month subscription, billed monthly, auto-renewal
      </Typography>
    ),
    trainingMessage: (
      <Typography
        variant="body1"
        sx={{
          fontWeight: 700,
          span: { color: '#ce0093' },
        }}
      >
        +$149 <span>(save $50)</span> for Foundational ILS Training
      </Typography>
    ),
    subDescription: ({ hasActiveSspSubscription }) => (
      <>
        <Typography variant="subtitle2">
          Includes One Starter Pack: Hardware Kit + Integration Kit
        </Typography>
        {hasActiveSspSubscription && (
          <Typography
            sx={{
              span: { color: '#ce0093' },
            }}
            variant="body2"
          >
            <br />
            Save an <span>additional $500</span> if you have an active SSP yearly subscription!
          </Typography>
        )}
      </>
    ),
    link: '/purchase/shipping',
    skus: ['focusCertification', 'focusMonthToMonth_Yearly_Bundle', 'FOCUS-STRT'],
    isActive: ({ hasFocusCertification, hasActiveFocusSubscription }) =>
      !hasFocusCertification && !hasActiveFocusSubscription,
    testName: 'ils-monthly-bundle-card',
  },
]
