import { gql, useQuery } from 'utils/apollo'
import get from 'lodash/get'

const GET_MAGENTO_ORDERS = gql`
  query getMagentoOrders {
    getMagentoOrders
  }
`
export default () => {
  const response = useQuery(GET_MAGENTO_ORDERS, { fetchPolicy: 'network-only' })
  const magentoOrders = get(response, 'data.getMagentoOrders', [])
  return { orders: magentoOrders }
}
