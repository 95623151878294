import React from 'react'
import { useMutation } from 'utils/apollo'
import { gql } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { Stack, Typography } from '@mui/material'
import get from 'lodash/get'
import { Button, Dialog, DialogTitle, DialogActions } from 'components'
import CloseSnackbarAction from 'components/CloseSnackbarAction'

const REVOKE_INVITATION = gql`
  mutation revokeInvitation($id: Int!) {
    revokeInvitation(id: $id)
  }
`

const RESEND_INVITATION = gql`
  mutation resendInvitation($id: Int!) {
    resendInvitation(id: $id)
  }
`

export default function InvitationDetails({ user, onUpdate }) {
  const { enqueueSnackbar } = useSnackbar()
  const [revokeInvitation] = useMutation(REVOKE_INVITATION, {
    update: onUpdate,
  })
  const [resendInvitation] = useMutation(RESEND_INVITATION, {
    update: onUpdate,
  })
  const [revoke, setRevoke] = React.useState(false)
  const [resend, setResend] = React.useState(false)
  const [pending, setPending] = React.useState(false)
  const mailgunMessage = get(user, 'mailgunMessage.metadata.mailgunMessage')

  const setMailgunMessageStatus = () => {
    const severity = get(user, 'mailgunMessage.metadata.severity')
    const deliveryStatus = get(user, 'mailgunMessage.metadata.deliveryStatus')

    /* Todo: Add the redirect text behind the failed messages to learn more about the error
    const redirectURL = ''
    const redirectText = (
      <a href={redirectURL} className="pl-1 text-link" target="_blank" rel="noopener noreferrer">
        Learn more.
      </a>
    ) 
    */

    if (severity === 'permanent') {
      return (
        <>
          <Typography p={1} variant="body1">
            Permanent Failure: this message could not be delivered.
          </Typography>
          <Stack
            direction="row"
            width="max-content"
            backgroundColor="rgb(245,245,245)"
            p={1}
            spacing={1}
            borderRadius="8px"
            fontSize={12}
          >
            <span>
              <b>Reason: </b>
            </span>
            <Stack direction="column">
              {(deliveryStatus.message || deliveryStatus.description || '')
                .split('\n')
                .map((message, index) => (
                  <span key={index}>{message}</span>
                ))}
            </Stack>
          </Stack>
        </>
      )
    } else if (severity === 'temporary') {
      return (
        <Typography p={1} variant="body1">
          Temporary Failure: attempts to deliver this message have failed. Delivery will be
          automatically retried.
        </Typography>
      )
    }
  }

  return (
    <div>
      {mailgunMessage === 'failed' && setMailgunMessageStatus()}

      <Button onClick={() => setRevoke(true)} className="m-1" color="primary">
        Revoke
      </Button>

      <Dialog open={revoke} onClose={() => setRevoke(false)}>
        <DialogTitle>Are you sure you want to revoke this invitation?</DialogTitle>
        <DialogActions>
          <Button
            className="m-1"
            color="primary"
            loading={pending}
            onClick={async () => {
              setPending(true)
              try {
                await revokeInvitation({
                  variables: {
                    id: user.id,
                  },
                })
                enqueueSnackbar('Invitation was successfully revoked', {
                  variant: 'success',
                  action: CloseSnackbarAction,
                })
                setRevoke(false)
              } catch (e) {
                console.error(e)
                enqueueSnackbar('Server error, try again', {
                  variant: 'error',
                  action: CloseSnackbarAction,
                })
              } finally {
                setPending(false)
              }
            }}
          >
            Yes
          </Button>
          <Button className="m-1" color="primary" onClick={() => setRevoke(false)}>
            No
          </Button>
        </DialogActions>
      </Dialog>

      <Button onClick={() => setResend(true)} className="m-1" color="primary">
        Resend
      </Button>

      <Dialog open={resend} onClose={() => setResend(false)}>
        <DialogTitle>Are you sure you want to resend this invitation?</DialogTitle>
        <DialogActions>
          <Button
            className="m-1"
            color="primary"
            loading={pending}
            onClick={async () => {
              setPending(true)
              try {
                await resendInvitation({
                  variables: {
                    id: user.id,
                  },
                })
                enqueueSnackbar('Invitation was successfully resent', {
                  variant: 'success',
                  action: CloseSnackbarAction,
                })
                setResend(false)
              } catch (e) {
                enqueueSnackbar('Server error, try again', {
                  variant: 'error',
                  action: CloseSnackbarAction,
                })
              } finally {
                setPending(false)
              }
            }}
          >
            Yes
          </Button>
          <Button className="m-1" color="primary" onClick={() => setResend(false)}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
