import React, { useState } from 'react'
import { CardElement } from '@stripe/react-stripe-js'
import { useSelector, useDispatch } from 'react-redux'
import get from 'lodash/get'

import { FormHelperText } from 'components'
import { setError, setShowError, setDisable, setPending } from 'store/modules/credit-card'
import { updatePurchase, extendData } from '../../store/modules/new-purchase'
import { useMediaQuery } from '@mui/material'
import useGTM from 'utils/hooks/useGTM'

export default ({
  addCard = () => {},
  hasShipping,
  updatePurchaseObject,
  setLoadingText = () => {},
  internalPurchase,
  authUpdatePurchaseObject = {},
  authUpdatePurchase = () => {},
  onPaymentCardChange = () => {},
  processCardOnChange = true,
  onCardInputFocus,
}) => {
  const dispatch = useDispatch()
  const [onFocus, setOnFocus] = useState(false)
  const { error, showError, pending } = useSelector((state) => state.creditCard)

  // we need to re-create purchase object here
  const { coupon } = useSelector((state) => state.newPurchase.data)
  const { addPaymentInfo } = useGTM()

  const processCard = async ({ postal_code }) => {
    // do loading
    dispatch(setPending(true))
    try {
      await dispatch(extendData({ postal_code }))

      if (!internalPurchase) {
        const { stripePaymentMethodId, stripeError } = await addCard()
        setLoadingText('calculating tax...')
        if (!stripeError) {
          const { error: updateError } = await dispatch(
            updatePurchase({
              ...updatePurchaseObject,
              billing_address: { postal_code },
              stripe_payment_method_id: stripePaymentMethodId,
              coupon_id: coupon?.data?.id,
            })
          )

          if (stripeError) {
            dispatch(setError(stripeError))
            dispatch(setShowError(true))
          }

          if (updateError) {
            dispatch(setError(updateError))
            dispatch(setShowError(true))
          }
        }

        await dispatch(extendData(stripePaymentMethodId))
      } else {
        // if we found an error with postal code, display error (tax does not update in internal purchase?)
        setLoadingText('calculating tax...')
        const result = await authUpdatePurchase({
          variables: {
            ...authUpdatePurchaseObject,
            params: {
              ...authUpdatePurchaseObject?.params,
              billing_address: { postal_code },
            },
          },
        })
        const tax = get(result, 'data.auth_update_purchase.stripe_invoice.tax', 0)
        const taxEnabled =
          get(result, 'data.auth_update_purchase.stripe_invoice.automatic_tax.enabled', null) ===
          true
        const taxComplete =
          get(result, 'data.auth_update_purchase.stripe_invoice.automatic_tax.status', null) ===
          'complete'

        if (taxEnabled && !taxComplete) {
          await dispatch(setError('Please update the credit card information'))
          await dispatch(setShowError(true))
        }

        await dispatch(
          extendData({
            totalTax: tax,
          })
        )
      }
    } catch (err) {
      console.error('cardinput', err)
    } finally {
      dispatch(setPending(false))
    }
  }

  const handleChange = async (event) => {
    setOnFocus(!event?.empty)
    onPaymentCardChange(event)
    if (event.complete) {
      await addPaymentInfo({ payment_type: event.brand })
      if (event.error) {
        await dispatch(setError(event.error.message))
        await dispatch(setShowError(true))
      } else if (event.complete) {
        await dispatch(setDisable(false))
        await dispatch(setShowError(false))

        if (processCardOnChange) {
          // pause everything and get tax
          await processCard({
            postal_code: event?.value?.postalCode,
          })
        }
      } else {
        await dispatch(setShowError(false))
        await dispatch(setDisable(true))
      }
    }
  }

  const matchWidth = useMediaQuery('(min-width: 500px)')

  return (
    <>
      <div style={{ position: 'relative' }}>
        {pending && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              cursor: 'default',
              zIndex: 10,
            }}
          />
        )}
        <div
          className="pb-2"
          style={{
            borderBottom: onFocus ? '2px solid #008080' : '1px solid rgba(0, 0, 0, 0.42)',
            transition: '200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            position: 'relative',
            '&:hover': {
              borderBottom: '2px solid rgba(0, 0, 0, 0.87)',
            },
          }}
        >
          <FormHelperText
            className="mb-2"
            style={{
              color: onFocus ? '#008080' : '',
              transition: '200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            }}
          >
            Card details
          </FormHelperText>
          <CardElement
            onChange={handleChange}
            onFocus={onCardInputFocus}
            options={{
              style: {
                base: {
                  color: '#32325d',
                  fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                  fontSmoothing: 'antialiased',
                  fontSize: matchWidth ? '16px' : '12px',
                  '::placeholder': {
                    color: '#aab7c4',
                  },
                },
                invalid: {
                  color: '#fa755a',
                  iconColor: '#fa755a',
                },
              },
              disabled: pending,
            }}
          />
        </div>
      </div>

      {showError && (
        <>
          <FormHelperText error data-test="stripe-error">
            Sorry but we had an issue adding your payment method.
            <br />
            For reference: "{error}"
            <br />
            Please try again or contact support if this issue persists.
          </FormHelperText>
        </>
      )}
    </>
  )
}
