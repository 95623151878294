import React, { ReactNode } from 'react'
import { Box, Card, Container, Stack, Typography, SxProps, Theme } from '@mui/material'

interface InfoMessageCardProps {
  icon?: ReactNode
  title: string | ReactNode
  descriptionText?: string
  descriptionNode?: ReactNode
  titleColor?: string
  containerSx?: SxProps<Theme>
  cardSx?: SxProps<Theme>
  iconBoxSx?: SxProps<Theme>
}

export function InfoMessageCard({
  icon,
  title,
  descriptionText,
  descriptionNode,
  containerSx,
  cardSx,
  iconBoxSx,
}: InfoMessageCardProps) {
  return (
    <Container
      maxWidth="lg"
      sx={{
        marginLeft: { lg: 'auto', md: 0 },
        ...containerSx,
      }}
    >
      <Card
        sx={{
          mx: 4,
          my: 6,
          bgcolor: '#e9eff4',
          borderRadius: '64px',
          ...cardSx,
        }}
        elevation={0}
        variant="outlined"
      >
        <Stack direction="column" alignItems="center" spacing={2} pb={6} px={6}>
          {icon && <Box sx={{ height: '4rem', paddingTop: '8px', ...iconBoxSx }}>{icon}</Box>}
          <Typography color="#3970ab" variant="h5" fontWeight={500} textAlign="center">
            {title}
          </Typography>
          {descriptionText && (
            <Typography variant="subtitle1" textAlign="center">
              {descriptionText}
            </Typography>
          )}
          {!descriptionText && descriptionNode}
        </Stack>
      </Card>
    </Container>
  )
}
