import { useEffect } from 'react'
import { useQuery, gql } from 'utils/apollo'
import get from 'lodash/get'
import { useDispatch, useSelector } from 'react-redux'
import { loadFFValues } from '../../store/modules/ff'
import { extendUser } from 'store/modules/auth'

const GET_USER = gql`
  query UseGetUserGetUser($filter: FilterUsersInput) {
    getUsers(filter: $filter) {
      id
      firstName
      lastName
      fullName
      roles
      email
      organization {
        id
        name
        createdAt
        settings
      }
      productPreferences
      professionalCredentials
      gender
      country
      birthYear
      uuid
    }
  }
`

export default (userId) => {
  const dispatch = useDispatch()
  const {
    refetchGetUser,
    hasFocusCertification,
    hasCompletedFocusCertification,
    hasSspCertification,
    hasCompletedSspCertification,
    hasRrpCertification,
    hasCompletedRrpCertification,
    ...rest
  } = useGetUserWithoutRefresh(userId)

  const _refetchGetUser = async () => {
    refetchGetUser()
    dispatch(loadFFValues())
  }
  useEffect(() => {
    const onFocus = () => {
      // stop refreshing when user no longer needs it
      if (
        (hasFocusCertification && !hasCompletedFocusCertification) ||
        (hasSspCertification && !hasCompletedSspCertification) ||
        (hasRrpCertification && !hasCompletedRrpCertification)
      ) {
        _refetchGetUser()
      }
    }

    // stop refreshing when user no longer needs it
    window.addEventListener('focus', onFocus)

    return () => {
      window.removeEventListener('focus', onFocus)
    }
    // eslint-disable-next-line
  }, [])

  return {
    refetchGetUser: _refetchGetUser,
    hasFocusCertification,
    hasCompletedFocusCertification,
    hasSspCertification,
    hasCompletedSspCertification,
    hasRrpCertification,
    hasCompletedRrpCertification,
    ...rest,
  }
}

export function useGetUserWithoutRefresh(userId) {
  const dispatch = useDispatch()
  const currentUserId = useSelector((state) => state.auth?.user?.id)
  const currentUser = useSelector((state) => state.auth.user)
  const useGetUserId = parseInt(userId) || currentUserId
  const {
    data: userData,
    loading: userLoading,
    refetch: refetchGetUser,
    previousData: previousUserData,
  } = useQuery(GET_USER, {
    skip: !useGetUserId,
    variables: { filter: { ids: [useGetUserId] } },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      // check for the existence of userId. If no queried user, then dispatch the current user with extendUser
      !parseInt(userId) && dispatch(extendUser(get(data, 'getUsers[0]', {})))
    },
  })
  const selectedUser = parseInt(userId) ? get(userData, 'getUsers[0]', {}) : currentUser

  const hasAssessmentProducts = useSelector((state) => state.ff.hasAssessmentProducts)

  const productPreferences = get(selectedUser, 'productPreferences', {})
  const sspInTrainingOnly = get(productPreferences, 'sspCertification.trainingOnly', false)
  const rrpInTrainingOnly = get(productPreferences, 'rrpCertification.trainingOnly', false)
  const focusInTrainingOnly = get(productPreferences, 'focusCertification.trainingOnly', false)

  const userRoles = get(selectedUser, 'roles', [])
  const hasSspCertification = !!productPreferences.sspCertification
  const hasRrpCertification = !!productPreferences.rrpCertification
  const hasFocusCertification = !!productPreferences.focusCertification

  const hasCompletedFocusCertification = !!productPreferences.focusCertification?.completedAt
  const hasCompletedSspCertification = !!productPreferences.sspCertification?.completedAt
  const hasCompletedRrpCertification = !!productPreferences.rrpCertification?.completedAt
  const hasVoiceProCertification = !!productPreferences.voiceProCertification
  const hasCompletedVoiceProCertification = !!productPreferences.voiceProCertification?.completedAt

  if (!currentUserId) {
    return { loading: true, selectedUser: {} }
  }

  return {
    loading: userLoading && !previousUserData,
    refetchGetUser,
    selectedUser: {
      ...selectedUser,
      hasFocusCertification,
      hasCompletedFocusCertification,
      hasSspCertification,
      hasCompletedSspCertification,
      hasRrpCertification,
      hasCompletedRrpCertification,
      hasVoiceProCertification,
      hasCompletedVoiceProCertification,
      hasAssessmentProducts,
      userRoles,
      sspInTrainingOnly,
      rrpInTrainingOnly,
      focusInTrainingOnly,
    },
  }
}
