/**
 * This hook uses `useContext` to store data that GTM needs to track transactions
 */
import { useState, useContext } from 'react'
import GTMContext from 'components/GTMContext'
import { ALL_CERTIFICATION_SKUS } from 'utils/constants/prices'
import get from 'lodash/get'
import { v4 as uuid } from 'uuid'
import { useMutation, ApolloClient, InMemoryCache } from '@apollo/client'
import { useSelector } from 'react-redux'
import { CREATE_FB_EVENT } from 'utils/apollo/purchaseService/client'

const GTM_NAME_MAPPING = {
  focusYearToYear_Yearly_Bundle: 'Focus YearToYear Yearly Subscription Bundle',
  focusMonthToMonth_Yearly_Bundle: 'Focus MonthToMonth Yearly Subscription Bundle',
}

export default function useGTM() {
  // 1. create some state to hold our gtm
  const [gtm, setGTM] = useContext(GTMContext)
  const [hasLogin, setHasLogin] = useState(false)

  const adminEmail = useSelector((state) => get(state, 'newPurchase.data.adminEmail'))
  const email = useSelector((state) => get(state, 'auth.user.email'))
  const client = new ApolloClient({
    uri: new URL(process.env.REACT_APP_PURCHASE_URL).toString(),
    cache: new InMemoryCache(),
  })
  const [createFbEvent] = useMutation(CREATE_FB_EVENT, { client })

  async function createFacebookEvent(fbEventParams, params = {}) {
    if (!(email || adminEmail || params.formEmail)) {
      return
    }

    createFbEvent({
      variables: {
        params: {
          ...fbEventParams,
          user_agent: navigator.userAgent,
          fbc: getFbcCookie(),
        },
        email: adminEmail || email || params.formEmail,
      },
    })
  }

  async function pageView({ pageUrl, pageTitle }) {
    await window.dataLayer.push({
      event: 'virtualPageview',
      pageUrl,
      pageTitle,
    })
  }

  /**
   * Discount is applied to certification (1st item) by default
   * Month To Month will have the price of the first month's subscription
   */
  async function addToCart({ items: _items, value, discount, ...userInfo }) {
    // 1. distinguish bundles by checking if there are more than one item with certification
    const hasCertification = _items?.find(({ item_id }) => ALL_CERTIFICATION_SKUS.includes(item_id))

    // we need if condition because we don't want to modify certification when its bought by itself
    const bundleItems =
      (hasCertification &&
        _items.length >= 2 &&
        _items.map((item) => {
          if (!item?.item_id?.includes('Bundle')) {
            const item_id = `${item.item_id}_Bundle`
            return {
              ...item,
              item_id,
              item_name: get(GTM_NAME_MAPPING, item_id, `${item.item_name} Bundle`),
            }
          } else {
            return {
              ...item,
              item_name: get(GTM_NAME_MAPPING, item.item_id, `${item.item_name} Bundle`),
            }
          }
        })) ||
      null

    const items = bundleItems || _items

    const newEcommerce = { ...gtm, currency: 'USD', items, value }
    await window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
    const fbEventParams = {
      event_id: uuid(),
      event: 'add_to_cart',
      ecommerce: newEcommerce,
      ...userInfo,
    }
    await window.dataLayer.push(fbEventParams)
    setGTM(newEcommerce)
    createFacebookEvent(fbEventParams)
  }

  /**
   * Begin Checkout does nothing but log the event,
   * the first step is now add to cart
   */
  async function beginCheckout(params = {}) {
    // get user info
    const newEcommerce = {
      ...gtm,
    }
    await window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
    const fbEventParams = {
      event_id: uuid(),
      event: 'begin_checkout',
      ecommerce: newEcommerce,
    }
    await window.dataLayer.push(fbEventParams)
    await setGTM(newEcommerce)
    createFacebookEvent(fbEventParams, params)
  }

  async function gtmBegin(event, obj) {
    const newGTM = { ...gtm, event, 'gtm.uniqueEventId': window.dataLayer.length, ...obj }
    setGTM(newGTM)
    window.dataLayer.push(newGTM)
  }

  async function addProfessionalCredentials({ professional_credentials }) {
    const newEcommerce = { ...gtm, professional_credentials }

    await window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
    await window.dataLayer.push({
      event: 'add_professional_credentials',
      ecommerce: newEcommerce,
    })
    setGTM(newEcommerce)
  }

  async function addShippingInfo({ shipping_tier }) {
    const newEcommerce = { ...gtm, shipping_tier }
    await window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
    await window.dataLayer.push({
      event: 'add_shipping_info',
      ecommerce: newEcommerce,
    })
    setGTM(newEcommerce)
  }

  function gtmSuccess({ revenue, id, coupon_code }) {
    const newGTM = {
      ...gtm,
      event: 'transaction',
      _event: 'transaction',
      'gtm.uniqueEventId': window.dataLayer.length,
      ecommerce: {
        ...gtm.ecommerce,
        purchase: {
          ...gtm.ecommerce.purchase,
          actionField: {
            ...gtm.ecommerce.purchase.actionField,
            revenue,
            id,
          },
        },
      },
    }
    setGTM(newGTM)
    window.dataLayer.push(newGTM)
  }

  /**
   * Add purchase event for GA4 and add transaction event for Google Universal
   */
  async function purchase({ transaction_id, coupon, shipping = 0, tax = 0 }) {
    const newEcommerce = {
      ...gtm,
      transaction_id,
      coupon,
      shipping,
      tax,
      value: gtm.value + shipping + tax,
    }

    // Google Universal: first
    await window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
    await window.dataLayer.push({
      event: 'transaction',
      transactionId: transaction_id,
      transactionTotal: gtm.value + shipping + tax,
    })

    // GA4: second
    await window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
    const fbEventParams = {
      event_id: uuid(),
      event: 'purchase',
      ecommerce: newEcommerce,
    }
    await window.dataLayer.push(fbEventParams)
    setGTM(newEcommerce)
    createFacebookEvent(fbEventParams)
  }

  async function addPromoCode({ coupon }) {
    const newEcommerce = { ...gtm, coupon }
    await window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
    await window.dataLayer.push({
      event: 'add_promo_code',
      ecommerce: newEcommerce,
    })
    setGTM(newEcommerce)
  }

  async function addPaymentInfo({ payment_type }) {
    const newEcommerce = { ...gtm, payment_type }
    await window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
    const fbEventParams = {
      event_id: uuid(),
      event: 'add_payment_info',
      ecommerce: newEcommerce,
    }
    await window.dataLayer.push(fbEventParams)
    setGTM(newEcommerce)
    createFacebookEvent(fbEventParams)
  }

  /**
   *
   *  log in GTM that we have logged in
   *  we don't have to worry about reset because login will refresh the page, resetting state
   */
  async function login({ ...userInfo }) {
    if (!hasLogin) {
      setHasLogin(true)
      await window.dataLayer.push({
        event: 'login',
        ...userInfo,
      })
    }
  }

  return {
    gtm,
    gtmBegin,
    gtmSuccess,

    // GA4
    pageView,
    beginCheckout,
    addProfessionalCredentials,
    addToCart,
    addShippingInfo,
    purchase,
    addPromoCode,
    addPaymentInfo,

    // UserData
    login,
  }
}

/**
  Get Facebook ClickId (_fbc) from cookies
  https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/fbp-and-fbc
*/
function getFbcCookie() {
  const cookies = document.cookie.split(';')
  for (const cookie of cookies) {
    const [name, value] = cookie.trim().split('=')
    if (name === '_fbc') {
      return decodeURIComponent(value)
    }
  }
  return null
}
