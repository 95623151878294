import React from 'react'
import get from 'lodash/get'
import { useSelector, useDispatch } from 'react-redux'
import ROLES from 'utils/constants/roles'
import { includesSome } from 'utils/includes'
import { useQuery } from 'utils/apollo'
import { useStateWatch } from 'hooks'
import { setActiveClientsData } from 'store/modules/activeClients'
import { Button, Grid } from 'components'
import ClientsTableColumns from './ClientsTableColumns'
import ClientsSearchAndFilterBar2 from './ClientsSearchAndFilterBar2'
import { useOutletContext } from 'react-router'
import LicensePromptOnClientCreation from './LicensePromptOnClientCreation'
import { GET_ACTIVE_CLIENTS_BY_IDS } from './constants/constants'
import { TableComponent2 } from 'components/table/TableComponent2'
import CreateClient from '../components/buttons/CreateClient'
import { useSnackbar } from 'notistack'
import CloseSnackbarAction from 'components/CloseSnackbarAction'
import { selectUser } from 'store/modules/clients'
import { Link } from 'react-router-dom'

// we will always retrieve all clients

export default () => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  // REDUX VARIABLES
  const userRoles = useSelector((state) => get(state, 'auth.user.roles', []))
  const users = useSelector((state) => state.clients.data)
  const { showNewSubscriptionPlan, showNewClientsPage } = useSelector((state) => state.ff)

  // TODO: REMOVE THIS
  const [canCreateOrInvite] = useStateWatch(
    () => !includesSome(userRoles, [ROLES.ADMIN, ROLES.ADMIN_NO_CLIENTS]),
    [userRoles]
  )

  const {
    refetch,
    initialQuery,
    queryVars,
    setQueryVars,
    setOpenLicensePrompt,
    slotsInfo,
  } = useOutletContext()

  const userIds = users.map(({ id }) => id)
  useQuery(GET_ACTIVE_CLIENTS_BY_IDS, {
    skip: !userIds?.length,
    onCompleted: (state) => dispatch(setActiveClientsData(state)),
  })

  const onUpdate = async (createdUserId) => {
    const newData = await refetch()
    const selected = get(newData, 'data.getUsers', []).findIndex(({ id }) => id === createdUserId)
    if (selected > -1) {
      dispatch(selectUser(selected))
    }
    enqueueSnackbar('User was created successfully', {
      variant: 'success',
      action: CloseSnackbarAction,
    })
  }

  // check whether to show license prompt
  const { availableSspSlots, availableRrpSlots, availableFocusSlots } = useSelector(
    (state) => state.clients
  )
  const { hasSspProducts, hasRrpProducts, hasFocusProducts } = useSelector((state) => state.ff)
  const noAvailableSspSlots = (availableSspSlots === 0 && slotsInfo.ssp.length) || !hasSspProducts
  const noAvailableFocusSlots =
    (availableFocusSlots === 0 && slotsInfo.focus.length) || !hasFocusProducts
  const noAvailableRrpSlots = (availableRrpSlots === 0 && slotsInfo.rrp.length) || !hasRrpProducts

  const noAvailableSlots = noAvailableSspSlots && noAvailableRrpSlots && noAvailableFocusSlots
  const handleClick = () => {
    if (showNewSubscriptionPlan && noAvailableSlots) {
      setOpenLicensePrompt(true)
    }
  }

  return (
    <div className="px-5 pb-5">
      <Grid container alignItems="center" className="pt-1 pb-1">
        {canCreateOrInvite && showNewClientsPage && (
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={noAvailableSlots && showNewSubscriptionPlan ? '/clients' : '/clients/create-client'}
            onClick={handleClick}
          >
            Add Client
          </Button>
        )}
        {canCreateOrInvite && !showNewClientsPage && (
          <CreateClient roles={[ROLES.CLIENT]} onUpdate={onUpdate} />
        )}
      </Grid>
      <Grid container alignItems="center" className="pt-2 pb-1" data-test="user-search">
        <ClientsSearchAndFilterBar2
          queryVars={queryVars}
          setQueryVars={setQueryVars}
          initialQuery={initialQuery}
        />
      </Grid>
      <TableComponent2 columns={ClientsTableColumns()} />
      <LicensePromptOnClientCreation />
    </div>
  )
}
