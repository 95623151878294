import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStripe, useElements, CardElement, Elements } from '@stripe/react-stripe-js'
import {
  Grid,
  Button,
  Typography,
  Box,
  TermsOfUse,
  Skeleton,
  CardHeader,
  CardContent,
  Divider,
  Tooltip,
} from 'components'
import makeStyles from '@mui/styles/makeStyles'
import get from 'lodash/get'
import { useSelector, useDispatch } from 'react-redux'
import CardInput from 'components/stripe/CardInput'
import Layout from './Layout'
import { setError, setShowError, setDisable, setPending } from 'store/modules/credit-card'
import {
  SSP_MONTHLY_SUBSCRIPTION_SKUS,
  FOCUS_SUBSCRIPTIONS_BUNDLE_SKUS,
  PRODUCTS,
} from 'utils/constants/prices'
import useCart from '../utils/useCart'
import formatMoney from '../utils/formatMoney'
import loadStripe from 'utils/loadStripe'
import {
  updatePurchase,
  finalizePurchase,
  getStripeSetupIntentClientSecret,
} from '../../../store/modules/new-purchase'
import useGTM from '../../../utils/hooks/useGTM'

const useStyles = makeStyles({
  cardHeader: {
    fontWeight: '500',
  },
})

const useStripeLoaded = () => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY)
  const [stripeLoaded, setStripeLoaded] = useState(false)

  useEffect(() => {
    stripePromise.then(() => setStripeLoaded(true)).catch(() => setStripeLoaded(false))
    // eslint-disable-next-line
  }, [stripePromise])

  return [stripeLoaded]
}

const Confirm = ({ next, step, initiateOnboarding, addPaymentInfo, extendData }) => {
  const navigate = useNavigate()
  const stripe = useStripe()
  const elements = useElements()
  const classes = useStyles()
  const dispatch = useDispatch()
  const [stripeLoaded] = useStripeLoaded()
  // renamed to gtmPurchase because there are too many variables with purchase here
  const { purchase: gtmPurchase, pageView } = useGTM()
  const { disable, pending } = useSelector((state) => state.creditCard)
  const [agreement, setAgreement] = useState(false)
  const [paymentCard, setPaymentCard] = useState()
  const [loadingText, setLoadingText] = useState()

  const getStripePaymentMethodId = async ({ stripeClientSecret }) => {
    const result = await stripe.confirmCardSetup(stripeClientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    })
    const stripePaymentMethodId = get(result, 'setupIntent.payment_method')
    const { message, code, decline_code } = get(result, 'error', {
      message: null,
      code: null,
      decline_code: null,
    })

    // don't return an error code if decline code don't exist and don't
    //   return error if message does not exist
    const errorCode = `Code: ${decline_code ? decline_code : code}.`
    const error = message ? `${message} ${errorCode}` : null

    await extendData({ stripePaymentMethodId })
    return { stripePaymentMethodId, stripeError: error }
  }

  const { selectedAddOnsArray, subscriptions, totalCharged, tax, shippingCost } = useCart()

  // get all values from redux because we are not getting any saved values here
  const {
    adminFirstName,
    adminLastName,
    organizationName,
    adminEmail,
    providerProfessionalCredentials,
    onboardingProducts,
    selectedServiceCode,
    coupon,
    appliedPromoCode,
    address1,
    address2,
    toPostalCode,
    toState,
    toCountry,
    toCity,
    shipstationRates = [],
    uuid,
    stripePaymentMethodId,
    updateError,
    postal_code,
  } = useSelector((state) => state.newPurchase.data)

  const hasPhysicalProduct = onboardingProducts.some(
    (sku) =>
      PRODUCTS[sku] &&
      (PRODUCTS[sku].type === 'physicalProduct' || PRODUCTS[sku].isPromoWithShipping)
  )
  const hasShipping = selectedAddOnsArray.length || hasPhysicalProduct

  // get shipstation rates
  const filterredRates = shipstationRates.filter(
    ({ serviceCode }) => serviceCode === selectedServiceCode
  )
  const { serviceCode } = get(filterredRates, '[0]', {
    serviceCode: '',
    shipmentCost: '',
    otherCost: '',
  })

  const handleEdit = () => {
    dispatch(setShowError(false))
    // remove all cart items about shipping here
    extendData({ selectedServiceCode: '', totalTax: 0 })
    next(0)
  }

  // column states the order to appear
  const summary = [
    {
      title: 'Contact Info:',
      value: `${adminFirstName} ${adminLastName} (${adminEmail})`,
      order: 1,
      isActive: true,
    },
    {
      title: 'Organization Name:',
      value: organizationName || `${adminFirstName} ${adminLastName}`,
      order: selectedAddOnsArray.length || 2,
      isActive: true,
    },
    {
      title: 'Professional Credentials:',
      value: providerProfessionalCredentials,
      order: selectedAddOnsArray.length || 3,
      isActive: true,
    },
    {
      title: 'Shipping Address:',
      value: (
        <>
          {address1}
          {address2 && (
            <>
              <br />
              {address2}
            </>
          )}
          <br />
          {toCity}, {toState}
          <br />
          {toPostalCode}, {toCountry}
        </>
      ),
      order: 4,
      isActive: hasShipping,
    },
  ]

  const updatePurchaseObject = {
    uuid: uuid,
    coupon_id: coupon?.data?.id,
    promotion_code: appliedPromoCode,
    shipping_address: hasShipping
      ? {
          address1,
          address2,
          postal_code: toPostalCode,
          country: toCountry,
          state: toState,
          city: toCity,
        }
      : {},
    shipment_method: hasShipping ? serviceCode : '',
    items: [
      ...selectedAddOnsArray.map(({ sku, quantity }) => ({
        sku: sku,
        quantity: quantity,
      })),
      ...onboardingProducts.map((item) => ({
        sku: item,
        quantity: 1,
      })),
    ],
    professional_credentials: providerProfessionalCredentials,
  }
  const handlePurchaseService = async () => {
    // set loading
    dispatch(setDisable(true))
    dispatch(setPending(true))
    dispatch(setShowError(false))
    dispatch(setError(null))

    try {
      setLoadingText('finalizing purchase...')
      const { error: updateError } = (await hasShipping)
        ? await dispatch(updatePurchase({ ...updatePurchaseObject }))
        : await dispatch(
            updatePurchase({
              ...updatePurchaseObject,
              billing_address: { postal_code },
              stripe_payment_method_id: stripePaymentMethodId,
              coupon_id: coupon?.data?.id,
              promotion_code: appliedPromoCode,
            })
          )

      const { error: finalizePurchaseError } =
        !updateError && (await dispatch(finalizePurchase({ uuid, stripePaymentMethodId })))

      // clear purchase
      if (!finalizePurchaseError && !updateError) {
        await gtmPurchase({
          value: totalCharged,
          transaction_id: uuid,
          coupon: appliedPromoCode,
          shipping: shippingCost,
          tax,
        })

        extendData({ uuid: undefined })
        navigate('/new-purchase/success')
      }
    } catch (err) {
      console.error('handle purchase service', err)
    } finally {
      dispatch(setDisable(false))
      dispatch(setPending(false))
    }
  }

  const addCard = async () => {
    try {
      setLoadingText('verifying card...')
      const { data: stripeClientSecret } = await dispatch(
        getStripeSetupIntentClientSecret({ uuid })
      )
      const stripePaymentMethodId = await getStripePaymentMethodId({
        stripeClientSecret,
      })

      const stripeError = get(stripePaymentMethodId, 'stripeError', undefined)

      if (stripeError) {
        dispatch(setError(stripeError))
        dispatch(setShowError(true))
      }

      return stripePaymentMethodId
    } catch (err) {
      console.error('addcard', err)
    }
  }

  const processCard = async () => {
    const postal_code = paymentCard?.value?.postalCode
    // do loading
    dispatch(setPending(true))
    try {
      await dispatch(extendData({ postal_code }))

      const { stripePaymentMethodId, stripeError } = await addCard()
      setLoadingText('calculating tax...')
      if (!stripeError) {
        const { error: updateError } = await dispatch(
          updatePurchase({
            ...updatePurchaseObject,
            billing_address: { postal_code },
            stripe_payment_method_id: stripePaymentMethodId,
            coupon_id: coupon?.data?.id,
          })
        )

        if (stripeError) {
          dispatch(setError(stripeError))
          dispatch(setShowError(true))

          console.log('stripeError: ', stripeError)
          setAgreement(false)
        }

        if (updateError) {
          dispatch(setError(updateError))
          dispatch(setShowError(true))
          console.log('updateError: ', updateError)
          setAgreement(false)
        }
      } else {
        setAgreement(false)
      }

      await dispatch(extendData(stripePaymentMethodId))
    } catch (err) {
      setAgreement(false)
    } finally {
      dispatch(setPending(false))
    }
  }

  const onTermsOfAgreementChange = async (isChecked) => {
    setAgreement(isChecked)
    if (isChecked) {
      await processCard()
    }
  }

  const handlePaymentCardChange = (card) => {
    if (agreement) {
      setAgreement(false)
    }
    setPaymentCard(card)
  }

  // remove tax and go back
  const handleBack = async () => {
    await dispatch(
      extendData({
        totalTax: 0,
      })
    )
    await dispatch(setShowError(false))
    await next(step - 1)
  }

  // on load: send virtual page load
  useEffect(() => {
    pageView({
      pageUrl: `${window.document.location.origin}${window.document.location.pathname}/confirm`,
      pageTitle: 'New Purchase - Enter billing info',
    })
    window.scrollTo(0, 0) // for mobile view to see promo code first
    // eslint-disable-next-line
  }, [])

  const isPurchaseButtonDisabled = !agreement || disable || !stripePaymentMethodId || updateError

  return (
    <Layout
      disableCartEdit={pending}
      isConfirmPage
      loading={pending}
      loadingText={loadingText}
      next={next}
      step={step}
    >
      <CardHeader
        title={`${step + 1}. Enter your billing info and confirm your purchase`}
        classes={{ title: classes.cardHeader }}
      />
      <Divider />
      <CardContent>
        <Grid container>
          <Grid item xs={10} container>
            {summary
              .sort((a, b) => a.order - b.order)
              .filter(({ isActive }) => Boolean(isActive))
              .map(({ title, value }, index) => (
                <Grid item xs={address1 ? 6 : 12} className="py-1" key={title}>
                  <Typography variant="body1" className="font-medium">
                    {title}
                  </Typography>
                  <Typography variant="body2" className="px-2">
                    {value}
                  </Typography>
                </Grid>
              ))}
          </Grid>
          <Grid item xs={1}>
            <Button color="primary" size="small" onClick={handleEdit} disabled={pending}>
              Edit
            </Button>
          </Grid>
        </Grid>
      </CardContent>
      <CardHeader title="Enter your billing information" classes={{ title: classes.cardHeader }} />
      <Divider />
      <CardContent>
        <div className="py-2" />
        {stripeLoaded ? (
          <CardInput
            addCard={addCard}
            updatePurchaseObject={{ ...updatePurchaseObject }}
            hasShipping={hasShipping}
            setLoadingText={setLoadingText}
            onPaymentCardChange={handlePaymentCardChange}
            processCardOnChange={false}
            onCardInputFocus={() => setAgreement(false)}
          />
        ) : (
          <Skeleton className="mt-2" variant="rectangular" height={30} />
        )}

        <Typography variant="caption">
          <Box className="pt-5" fontWeight="fontWeightBold">
            You will {!totalCharged && 'not'} be charged{' '}
            {totalCharged > 0 && formatMoney(totalCharged)} today.
          </Box>
        </Typography>
        {subscriptions.map(({ price, frequency, sku }, index) => (
          <Typography variant="caption" gutterBottom key={`subscription${index}`}>
            You will automatically be charged {formatMoney(price)}/{frequency} + tax
            {SSP_MONTHLY_SUBSCRIPTION_SKUS.includes(sku) &&
              ' after completing your Foundational SSP Training'}
            {FOCUS_SUBSCRIPTIONS_BUNDLE_SKUS.includes(sku) &&
              ' after completing your Foundational ILS Training'}
            .
          </Typography>
        ))}

        <div className="my-5">
          <TermsOfUse
            disabled={pending}
            checked={agreement && !updateError}
            onChange={onTermsOfAgreementChange}
          />
        </div>

        <Grid className="mt-5" container justifyContent="space-between">
          <Button disabled={pending} color="primary" onClick={handleBack}>
            Back
          </Button>
          <Tooltip
            title={
              isPurchaseButtonDisabled
                ? 'Enter valid payment information and accept terms and conditions'
                : ''
            }
          >
            <span>
              <Button
                variant="contained"
                color="primary"
                disabled={isPurchaseButtonDisabled}
                onClick={handlePurchaseService}
                data-test="confirm-purchase"
              >
                Confirm Purchase
              </Button>
            </span>
          </Tooltip>
        </Grid>
      </CardContent>
    </Layout>
  )
}

export default (props) => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY)

  return (
    <Elements stripe={stripePromise}>
      <Confirm {...props} />
    </Elements>
  )
}
