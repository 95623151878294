/**
 * This component is only for Basic Info and was refactored out of wizard as there was a rerender bug when an error exists
 */

import React, { useContext } from 'react'
import { ProviderProfileContext, VariantWrapper } from '../MyProfile'
import { Grid, Typography } from '@mui/material'
import { isEmpty } from 'utils/isEmpty'
import { Link, useOutletContext } from 'react-router-dom'
import { SingleAddressForm } from './Wizard'
import formatAddress from 'utils/formatAddress'
import get from 'lodash/get'

export default function BusinessAddress() {
  const { form, wizardState, setWizardState } = useContext(ProviderProfileContext)
  const { setAutoFocusLabelName } = useOutletContext()

  const isEditBasicInfo = wizardState?.includes('BASIC_INFO/EDIT')
  const addresses = get(form, 'addresses', [{}])
  const businessAddress =
    addresses.find((address) => address?.locationName === 'Business Address') || {}
  const _businessAddressIndex = addresses.findIndex(
    (address) => address?.locationName === 'Business Address'
  )
  const businessAddressIndex =
    _businessAddressIndex === -1 ? addresses.length : _businessAddressIndex

  const handleChangeBusinessAddress = () => {
    setWizardState(`BASIC_INFO/EDIT_${businessAddressIndex}`)
    setAutoFocusLabelName('Address')
  }

  return (
    <>
      {!isEditBasicInfo && (
        <VariantWrapper lastItem>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <Typography sx={{ color: 'rgba(0, 0, 0, 0.42)' }} variant="body1">
                Business Address
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography variant="body1" sx={{ color: 'gray !important' }}>
                {!isEmpty(businessAddress) && formatAddress(businessAddress)}
              </Typography>
              <Link to="/my/profile" onClick={handleChangeBusinessAddress}>
                <Typography variant="caption">
                  {!isEmpty(businessAddress) && formatAddress(businessAddress) ? 'Change' : 'Add'}{' '}
                  business address
                </Typography>
              </Link>
            </Grid>
          </Grid>
        </VariantWrapper>
      )}
      {isEditBasicInfo && (
        <>
          <Typography sx={{ color: 'rgba(0, 0, 0, 0.42)' }} variant="body1">
            Business Address
          </Typography>
          <SingleAddressForm openFromProps idx={businessAddressIndex} address={businessAddress} />
        </>
      )}
    </>
  )
}
