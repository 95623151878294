/*
 * We moved the provider help button into its own component to dynamically load hubspot
 */

import React, { useEffect } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import { Badge, Button, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'

import links from 'utils/constants/links'
import useLocalStorage from 'utils/hooks/useLocalStorage'

import { setLoaded, setOpenHubspot, setUnreadCount } from 'store/modules/hubspot'

const GET_HUBSPOT_CONVERSATION_TOKEN_FROM_HEADER = gql`
  query GetHubSpotConversationTokenFromHeader {
    getHubspotConversationToken
  }
`

declare global {
  interface Window {
    HubSpotConversations: any // You can replace 'any' with a more specific type if you have the details
    hsConversationsSettings: any
    hsConversationsOnReady: any
    hsCookieBanner: any
  }
}

interface ProviderHelpButtonProps {
  userId: string
  email: string
}

const ProviderHelpButton: React.FC<ProviderHelpButtonProps> = ({ userId, email }) => {
  // hooks
  const dispatch = useDispatch()
  const { loaded, unreadCount } = useSelector((state) => get(state, 'hubspot', {}))

  const [hubSpotConversationTokenData, setHubSpotConversationTokenData] = useLocalStorage(
    `hubspotConversationToken${userId}`
  )
  const [getHubSpotConvserationToken, hubSpotConvserationTokenResponse] = useLazyQuery(
    GET_HUBSPOT_CONVERSATION_TOKEN_FROM_HEADER,
    {
      onCompleted: (data) => {
        const identificationToken = get(data, 'getHubspotConversationToken', '')
        setHubSpotConversationTokenData({ identificationToken, currentTime: Date.now() })
        window.hsConversationsSettings = {
          ...window.hsConversationsSettings,
          identificationEmail: email,
          identificationToken,
        }
      },
    }
  )

  const calledGetHubSpotConvserationToken = get(hubSpotConvserationTokenResponse, 'called', false)

  /**
   * 1. check if we have called within 12 hours
   * 2. check if we have already called this function
   * 3. get token and save into local storage
   */
  const openHubSpotConversations = async () => {
    dispatch(setOpenHubspot(true))
    try {
      if (window.HubSpotConversations) {
        const hasCalledWithin12Hours =
          hubSpotConversationTokenData?.currentTime &&
          Math.abs(Date.now() - hubSpotConversationTokenData?.currentTime) <= 12 * 60 * 60 * 1000

        if (hasCalledWithin12Hours && hubSpotConversationTokenData?.identificationToken) {
          window.hsConversationsSettings = {
            ...window.hsConversationsSettings,
            identificationToken: hubSpotConversationTokenData?.identificationToken,
            identificationEmail: email,
          }
        } else if (!calledGetHubSpotConvserationToken) {
          await getHubSpotConvserationToken()
        }

        if (!loaded) {
          dispatch(setLoaded(true))
          await window.HubSpotConversations.widget.load()
        } else {
          await window.HubSpotConversations.widget.refresh()
        }
        await window.HubSpotConversations.widget.open()
      }

      // navigate to help center page if above breaks
      if (!window.HubSpotConversations) {
        window.open(links.support.homepageUrl, '_blank')
        dispatch(setOpenHubspot(false))
      }
    } catch (error) {
      console.error(error)
      window.open(links.support.homepageUrl, '_blank')
      dispatch(setOpenHubspot(false))
    }
  }

  // we put onLoad function here because we don't want multiple event listeners
  useEffect(() => {
    const onLoad = () => {
      // this only happens once.....
      window.HubSpotConversations.on('unreadConversationCountChanged', (payload) => {
        window.HubSpotConversations.widget.open()
        dispatch(setOpenHubspot(true))
        dispatch(setUnreadCount(payload.unreadCount))
      })
    }
    window.hsConversationsOnReady = [onLoad]
  }, [])

  return (
    <Badge
      color="secondary"
      variant="dot"
      invisible={unreadCount === 0}
      sx={{
        '& .MuiBadge-badge': {
          width: '1rem',
          height: '1rem',
          borderRadius: '9999px',
        },
      }}
    >
      <Button
        aria-label="open-hubspot-conversation"
        variant="contained"
        color="primary"
        data-test="help-button"
        onClick={openHubSpotConversations}
      >
        <Typography variant="button">Help</Typography>
      </Button>
    </Badge>
  )
}

export default ProviderHelpButton
