/**
 * When a user lands on this page, we will check redux if we have pulled information from dear
 */
import React, { useEffect, useState } from 'react'
import {
  Button,
  useMediaQuery,
  Grid,
  Typography,
  CardHeader,
  Divider,
  CardContent,
} from 'components'
import makeStyles from '@mui/styles/makeStyles'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import Product from './Product'
import Layout from './Layout'
import useGTM from 'utils/hooks/useGTM'
import useCart from '../utils/useCart'
import { useSnackbar } from 'notistack'
import { PRODUCTS } from '../../../utils/constants/prices'
import LINKS from 'utils/constants/links'
import CloseSnackbarAction from 'components/CloseSnackbarAction'

const useStyles = makeStyles({
  cardHeader: {
    fontWeight: '500',
  },
})

export default function Accessories({ next, step, getDearProducts }) {
  const matches = useMediaQuery('(min-width:450px)')
  const classes = useStyles({ matches })
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  // get dear products and filter by sku
  const products = useSelector((state) => get(state, 'newPurchase.data.products', []))
  const reduxData = useSelector((state) => state.newPurchase.data)
  const skus = reduxData.onboardingProducts || []
  const getDearProductsErrors = get(reduxData, 'getDearProductsErrors', '')
  const accessoryCategories = [].concat.apply(
    [],
    skus.map((sku) => PRODUCTS[sku].accessoryCategories)
  )

  const fetch = async (accessoryCategories) => {
    try {
      await setLoading(true)
      await getDearProducts({ productCategories: accessoryCategories })
    } catch (error) {
      enqueueSnackbar('Server error.. Please try again later.', {
        variant: 'error',
        action: CloseSnackbarAction,
      })
    } finally {
      await setLoading(false)
    }
  }

  const { gtm, addToCart, pageView } = useGTM()
  useEffect(() => {
    if (!products.length) {
      fetch(accessoryCategories)
    }

    // on load: send virtual page load
    pageView({
      pageUrl: `${window.document.location.origin}${window.document.location.pathname}/accessories`,
      pageTitle: 'New Purchase - Select optional accessories',
    })

    // eslint-disable-next-line
  }, [])

  const onRetry = () => {
    fetch(accessoryCategories)
  }

  const handleContinue = () => {
    // parse localstorage and add array to GTM
    const selectedAddOns = get(reduxData, 'selectedAddOns', {})
    const selectedAddOnsArray = products
      .map(({ SKU, PriceTier1, Name }) => ({
        item_id: SKU,
        item_name: Name,
        price: PriceTier1,
        quantity: get(selectedAddOns, SKU, 0),
        discount: 0,
      }))
      .filter(({ quantity }) => Boolean(quantity))
    const items = [...selectedAddOnsArray, ...gtm.items]
    const value = items.reduce((sum, item) => sum + (item.price - item.discount), 0)

    // only add to cart when there are items selected
    selectedAddOnsArray.length && addToCart({ items, value })
    next()
  }

  // check cart items
  const { selectedAddOnsArray } = useCart()
  const continueText = selectedAddOnsArray.length ? 'Continue' : 'No Thanks'
  const cardHeaderTitle = accessoryCategories.includes('ssp') && 'SSP Headphones'

  return (
    <Layout next={next} loading={loading}>
      <div className="pb-5">
        <CardHeader
          data-test="card-header"
          title={`${step + 1}. Please select any optional accessories`}
          classes={{ title: classes.cardHeader }}
        />
        <CardContent>
          <Typography gutterBottom variant="body1">
            Optionally add any of these accessories to your order. Shipping costs (if any) will be
            calculated at the next step.
          </Typography>
          <Grid container justifyContent="space-between" alignItems="center" className="py-4">
            <Button color="primary" onClick={() => next(step - 1)}>
              Back
            </Button>
            <Button
              data-test="submit-button"
              variant="contained"
              color="primary"
              onClick={handleContinue}
            >
              {continueText}
            </Button>
          </Grid>
        </CardContent>
        <Divider />
      </div>
      {!loading && !!products.length && (
        <>
          <CardHeader title={cardHeaderTitle} classes={{ title: classes.cardHeader }} />
          {accessoryCategories.includes('ssp') && (
            <>
              <CardContent key="products">
                <Typography gutterBottom variant="body1">
                  We recommend over-the-ear (circumaural) headphones for their enclosed nature and
                  the immersive sound experience when using the SSP.
                </Typography>
                <Typography gutterBottom variant="body1">
                  You can use your own headphones or purchase Unyte Health headphones. See
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="px-1 text-link"
                    href={LINKS.support.sspHeadphonesUrl}
                  >
                    here
                  </a>
                  for other headphones options.
                </Typography>
              </CardContent>
              <Divider />
            </>
          )}
          <Grid container direction="column" className={`py-2 ${classes.container}`}>
            {products.map((product, index) => (
              <div key={`productWrapper${index}`}>
                <Product key={`product${index}`} product={product} index={index} />
                <Divider />
              </div>
            ))}
          </Grid>
        </>
      )}
      {!loading && !products.length && (
        <CardContent key="errors">
          <Typography gutterBottom variant="body1" color="error">
            Sorry, but optional accessories are currently unavailable. <br />
            For reference: "{getDearProductsErrors}".
            <br />
            Please
            <span className="underline cursor-pointer px-1" onClick={onRetry}>
              try again
            </span>
            or contact support if this issue persists.
          </Typography>
        </CardContent>
      )}
    </Layout>
  )
}
