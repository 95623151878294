/**
 * When displaying info, we'll have grayed out (disabled) fields
 * When updating info, we'll use MUI Textfields, Selects, etc
 */
import React, { useState } from 'react'
import {
  Container,
  Grid,
  Stack,
  Typography,
  Divider,
  Button as MuiButton,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material'
import PhoneIcon from '@mui/icons-material/Phone'
import { useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'

// icons
import { gql, useMutation, useQuery } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { setOrganizationValue } from 'store/modules/organization'
import { UpdateProfileCard, useMyForm } from './MyProfile'
import MuiPhoneNumber from 'material-ui-phone-number'
import CloseSnackbarAction from 'components/CloseSnackbarAction'
import { TEXTFIELD_VARIANT } from 'utils/theme/Theme'
import ROLES from 'utils/constants/roles'
import { useOutletContext } from 'react-router'

const UPDATE_ORGANIZATION = gql`
  mutation updateOrganization($organization: UpdateOrganizationInput!) {
    updateOrganization(organization: $organization) {
      name
      organizationProfileInfo
    }
  }
`

const GET_BILLING_USERS_FOR_ORGANIZATION = gql`
  query GetBillingUsersForOrganization($filter: FilterUsersInput) {
    getUsers(filter: $filter) {
      id
      roles
      fullName
    }
  }
`

const GET_ME_FOR_ORGANIZATION = gql`
  query GetMeForOrganization {
    getMe {
      id
      phone
      roles
    }
  }
`

const Button = ({ children, onClick }) => {
  return (
    <MuiButton variant="text" sx={{ margin: '1rem' }} onClick={onClick}>
      {children}
    </MuiButton>
  )
}

/**
 *
 * only show default phone number when we have only 1 billing user
 * - the default phone number will be the billing user's phone number
 */
export default function MyOrganization() {
  const dispatch = useDispatch()
  const { loading, setLoading } = useOutletContext()
  const { enqueueSnackbar } = useSnackbar()
  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION)

  // get most up to date info
  const { data: getMeData } = useQuery(GET_ME_FOR_ORGANIZATION, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      const phone = get(response, 'getMe.phone', null)
      if (!organizationProfileInfo?.phone && numberOfBillingUsers === 1 && isBilling && phone) {
        handleTextField('phone')(phone)
      }
    },
  })
  const { phone, roles } = get(getMeData, 'getMe', {})

  const { data: userData } = useQuery(GET_BILLING_USERS_FOR_ORGANIZATION, {
    variables: {
      filter: {
        anyRoles: ['billing'],
      },
    },
  })
  const numberOfBillingUsers = get(userData, 'getUsers', []).length

  // create form for updating user
  const { id, organizationProfileInfo, name } = useSelector((state) =>
    get(state, 'organization', {})
  )
  const isBilling = roles?.includes(ROLES.BILLING)

  // states for updating
  const [editStates, setEditStates] = useState({})

  const handleEdit = (label) => () => {
    setEditStates({ ...editStates, [label]: true })
  }
  const handleCancel = (label) => () => {
    setEditStates({ ...editStates, [label]: false })
    resetForm()
  }

  const handleSave = async () => {
    await setLoading(true)

    try {
      const res = await updateOrganization({
        variables: {
          organization: {
            id,
            name: form.name,
            organizationProfileInfo: {
              website: form.website,
              organizationSize: form.organizationSize,
              phone: form.phone,
            },
          },
        },
      })
      dispatch(setOrganizationValue(res.data.updateOrganization))
      enqueueSnackbar('Update successful', { variant: 'success', action: CloseSnackbarAction })
    } catch (err) {
      enqueueSnackbar(`Failed to save. ${err.message}`, {
        variant: 'error',
        action: CloseSnackbarAction,
      })
      resetForm()
    } finally {
      setEditStates({})
      setLoading(false)
    }
  }

  const updateOrganizationInfo = editStates.organizationInfo

  // only display personal phone number if we are billing user as well
  const { form, handleTextField, resetForm } = useMyForm({
    phone:
      organizationProfileInfo?.phone || (numberOfBillingUsers === 1 && isBilling && phone) || '',
    website: organizationProfileInfo?.website || '',
    name: name || '',
    organizationSize: organizationProfileInfo?.organizationSize || '',
    organizationProfileInfo: organizationProfileInfo?.organizationProfileInfo,
  })

  const VIEW_FIELDS = [
    {
      label: 'Organization Name',
      value: form.name,
      disabled: false,
    },
    {
      label: 'Organization Size',
      value: form.organizationSize,
      disabled: false,
      type: 'list',
    },
    { label: 'Website', type: 'website', value: form.website, disabled: false },
    {
      label: 'Phone',
      type: 'phone',
      value: form.phone,
      disabled: false,
    },
  ]

  const UPDATE_FIELDS = [
    {
      Component: TextField,
      defaultValue: form.name,
      label: 'Organization Name',
      id: 'organization-name',
      disabled: false,
      InputLabelProps: { shrink: true },
      onChange: handleTextField('name'),
      hide: !updateOrganizationInfo,
      fullWidth: true,
    },
    {
      Component: () => (
        <FormControl disabled={!isBilling} fullWidth>
          <InputLabel id="organization-size-label">Organization Size</InputLabel>
          <Select
            label="Organization Size"
            labelId="select-organization-size"
            id="select-organization-size-id"
            value={form.organizationSize}
            onChange={handleTextField('organizationSize')}
          >
            <MenuItem data-test="confirm-accept-insurance-yes" value="1-4 Providers">
              1-4 Providers
            </MenuItem>
            <MenuItem data-test="confirm-accept-insurance-no" value="5-9 Providers">
              5-9 Providers
            </MenuItem>
            <MenuItem data-test="confirm-accept-insurance-no" value="10+ Providers">
              10+ Providers
            </MenuItem>
            <MenuItem data-test="confirm-accept-insurance-no" value="Not Applicable">
              Not Applicable
            </MenuItem>
          </Select>
        </FormControl>
      ),
      hide: !updateOrganizationInfo,
    },
    {
      Component: TextField,
      fullWidth: true,
      value: form.website,
      label: 'Website',
      id: 'website',
      onChange: handleTextField('website'),
      hide: !updateOrganizationInfo,
      disabled: !isBilling,
    },
    {
      Component: MuiPhoneNumber,
      fullWidth: true,
      variant: TEXTFIELD_VARIANT,
      label: 'Phone',
      id: 'phone',
      value: form.phone,
      onChange: handleTextField('phone'),
      name: 'phonemask',
      disableAreaCodes: true,
      preferredCountries: ['ca', 'us'],
      defaultCountry: 'us',
      sx: { width: '100%' },
      hide: !updateOrganizationInfo,
      disabled: !isBilling,
    },
  ]

  return (
    <Container maxWidth="lg" sx={{ margin: '1rem 0 1rem 0' }}>
      <Stack direction="column">
        <UpdateProfileCard
          title="Organization Info"
          handleCancel={handleCancel('organizationInfo')}
          handleSave={handleSave}
          fields={UPDATE_FIELDS}
          // error={error}
          hideButtons={true}
          lastUpdatedAt={organizationProfileInfo?.organizationInfoUpdatedAt}
        >
          {!updateOrganizationInfo && (
            <>
              <Stack direction="column" divider={<Divider />} spacing={2}>
                {VIEW_FIELDS.map(({ label, value, type }) => (
                  <Grid container>
                    <Grid item xs={12} sm={4}>
                      <Typography variant="body2" sx={{ color: 'text' }}>
                        {label}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      {type !== 'phone' && (
                        <Typography variant="body2" sx={{ color: 'gray !important' }}>
                          {value}
                        </Typography>
                      )}
                      {type === 'phone' && (
                        <Stack direction="row">
                          <PhoneIcon color="primary" />
                          <Typography variant="body2" sx={{ color: 'gray !important', m: 0.5 }}>
                            {form.phone}
                          </Typography>
                        </Stack>
                      )}
                    </Grid>
                  </Grid>
                ))}
              </Stack>
              <Divider />
            </>
          )}
          <Stack direction="row" spacing={2} sx={{ marginLeft: '-1.75rem !important' }}>
            {!updateOrganizationInfo && isBilling && (
              <span>
                <Button onClick={handleEdit('organizationInfo')}>Edit Organization Info</Button>
              </span>
            )}
            {updateOrganizationInfo && (
              <>
                <span>
                  <Button onClick={handleSave} disabled={loading}>
                    Save
                  </Button>
                </span>
                <span>
                  <Button onClick={handleCancel('organizationInfo')} disabled={loading}>
                    Cancel
                  </Button>
                </span>
              </>
            )}
          </Stack>
        </UpdateProfileCard>
      </Stack>
    </Container>
  )
}
