import http from 'utils/http'
import get from 'lodash/get'

/**
 * Retrieve user that has newEmail equal to newEmail in query and changeEmailUuid. Returns error if not found.
 */
export function verifyEmailChange({ newEmail, changeEmailUuid }) {
  return async (dispatch) => {
    try {
      const result = await http.post('/verify-email-change', {
        newEmail,
        changeEmailUuid,
      })
      const emailChangeVerifiedResult = get(result, 'data.data.emailChangeVerified', false)
      return { emailChangeVerifiedResult, error: false }
    } catch (error) {
      console.error('verifyEmailChange', error)
      return { emailChangeVerifiedResult: false, error: true }
    }
  }
}
