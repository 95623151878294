import { ASSESSMENT_STATUS } from 'utils/constants/assessmentStatus'
import { matchPath } from 'react-router-dom'

export const ACTIONS = {
  [ASSESSMENT_STATUS.COMPLETED]: { message: 'Assessment saved successfully', variant: 'success' },
  [ASSESSMENT_STATUS.IN_PROGRESS]: { message: 'Assessment saved successfully', variant: 'success' },
  [ASSESSMENT_STATUS.SENT_TO_CLIENT]: { message: 'Assessment sent to client', variant: 'success' },
  [ASSESSMENT_STATUS.IN_PROGRESS_BY_CLIENT]: {
    message: 'Assessment in progress by client',
    variant: 'success',
  },
  error: { message: 'Something went wrong - please try again later', variant: 'error' },
}

export const tabs = [
  {
    text: 'Assessments',
    url: '/assessments',
    pathPattern: '/assessments/:userId',
    state: { hideGettingStarted: true },
    exact: true,
    isActive: (pathname = '') => {
      return (
        !matchPath({ path: '/assessments/reports/*' }, pathname) &&
        matchPath({ path: '/assessments/*' }, pathname)
      )
    },
  },
]

export const NEW_UPDATE_VIDEO = '//player.vimeo.com/video/610039729'
export const RESOURCE_INTRO_VIDEO = '//player.vimeo.com/video/649132437'
export const ASSESSMENT_BEST_PRACTICES_VIDEO = '//player.vimeo.com/video/838105256'

export const ASSESSMENTS_EMAIL = 'assessments@unyte.com'

export const PAGELOADER_TIMER = 1250
